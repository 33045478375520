export default {
  'onlineOrderingSettings.title': 'Online Ordering',
  'onlineOrderingSettings.tabs.general': 'General',
  'onlineOrderingSettings.tabs.display': 'Display',
  'onlineOrderingSettings.tabs.feeAndTips': 'Fee & Tips',

  'onlineOrderingSettings.section.banner': 'Banner',
  'onlineOrderingSettings.section.banner.desktop': 'Desktop View',
  'onlineOrderingSettings.section.mobile_banner': 'Mobile View',
  'onlineOrderingSettings.section.notice': 'Announcement',
  'onlineOrderingSettings.section.layout': 'Menu Layout',
  'onlineOrderingSettings.section.top.banner.title':
    'Elevated Online Ordering Experience—No Commission Fees, Higher Margins!',
  'onlineOrderingSettings.section.top.banner.content':
    'Unlock the power of increased online visibility—guests will easily find your business on Google Search and Maps, and place orders directly from your website. With no commission fees, you can keep more profit in your pocket!',
  'onlineOrderingSettings.section.top.banner.preview': 'Preview Website',
  'onlineOrderingSettings.section.onlineOrderHours': 'Online Order Hours',
  'onlineOrderingSettings.section.businessHour': 'Business hour',
  'onlineOrderingSettings.section.specialTime': 'Special/Holiday Hour',
  'onlineOrderingSettings.section.specialTime.timeOverlapDetailed':
    'Time range {current} overlaps with existing time range {overlapping}',
  'onlineOrderingSettings.section.menuHour': 'Menu hour',
  'onlineOrderingSettings.section.preparation': 'Order Preparation',
  'onlineOrderingSettings.section.autoConfirmOrders': 'Order Auto-Confirmation & Send',
  'onlineOrderingSettings.section.scheduledOrder': 'Scheduled Order',
  'onlineOrderingSettings.section.fee': 'Fees',
  'onlineOrderingSettings.section.tips': 'Tip Calculations',
  'onlineOrderingSettings.section.tips.taxed': 'Taxes',
  'onlineOrderingSettings.fee.new_fee': '+ Add New Fee',
  'onlineOrderingSettings.fee.validation.value_must_greater_than_zero': 'Value must be greater than 0',
  'onlineOrderingSettings.fee.self_delivery': 'Self Delivery Fee',
  'onlineOrderingSettings.fee.self_delivery_minimum_order': 'Minimum Order Value Setting',
  'onlineOrderingSettings.fee.self_delivery_minimum_order_tooltip':
    'If configured, customers cannot proceed to checkout if the subtotal does not meet the minimum order value.',
  'onlineOrderingSettings.fee.self_delivery_no_minimum_order_value': 'No Minimum Order Value',
  'onlineOrderingSettings.fee.self_delivery_minimum_order_value': 'Minimum Order Value',
  'onlineOrderingSettings.fee.self_delivery_free_delivery': 'Free Food Delivery Service Setting',
  'onlineOrderingSettings.fee.self_delivery_free_delivery_tooltip':
    'If configured, free delivery will be provided when the subtotal exceeds the specified threshold.',
  'onlineOrderingSettings.fee.self_delivery_free_delivery_no_free': 'No Free Delivery Service Available',
  'onlineOrderingSettings.fee.self_delivery_free_delivery_free_value': 'Free Delivery On Order Over ${thresholdValue}',
  'onlineOrderingSettings.fee.self_delivery_free_delivery_free_and_minimum_valid':
    'This amount must be higher than the minimum order value, if applicable',
  'onlineOrderingSettings.fee.table.action': 'Action',
  'onlineOrderingSettings.fee.table.no': 'No',
  'onlineOrderingSettings.fee.table.yes': 'Yes',
  'onlineOrderingSettings.fee.table.delivery.tip': 'Quick access when creating Online In delivery orders',

  'onlineOrderingSettings.section.preparation.prepareTime': 'Kitchen Preparation Time',
  'onlineOrderingSettings.section.preparation.prepareTime.content':
    'Controls the duration required to complete the preparation of online orders. This time setting is also used to calculate the estimated delivery or pickup window provided to guests when they place orders online.',
  'onlineOrderingSettings.section.preparation.order_acceptance_mode': 'Order Acceptance Mode',
  'onlineOrderingSettings.section.preparation.order_acceptance_mode.content':
    'Online orders can be accepted either automatically or manually. To configure this, simply adjust the related setting in the ‘Send’ section.',
  'onlineOrderingSettings.section.fee.send_setting_section': "Send' Setting Section",
  'onlineOrderingSettings.section.preparation.': '',
  'onlineOrderingSettings.section.preparation.schedulingSettings': 'Maximum Scheduled Order Date',
  'onlineOrderingSettings.section.preparation.schedulingSettings.content':
    'Define the maximum number of days in advance that guests can place scheduled orders. ',
  'onlineOrderingSettings.section.notice.titleEN': 'Title',
  'onlineOrderingSettings.section.notice.english': 'English',
  'onlineOrderingSettings.section.notice.chinese': 'Foreign',
  'onlineOrderingSettings.section.notice.contentEN': 'Content',
  'onlineOrderingSettings.section.notice.titleZH': 'Foreign Title',
  'onlineOrderingSettings.section.notice.contentZH': 'Foregin Content',
  'onlineOrderingSettings.section.layout.pc': 'Desktop View',
  'onlineOrderingSettings.section.layout.mobile': 'Mobile View',
  'onlineOrderingSettings.section.layout.recommend': ' (recommend)',
  'onlineOrderingSettings.section.layout.image_left': 'image-left',
  'onlineOrderingSettings.section.layout.image_right': 'image-right',
  'onlineOrderingSettings.section.layout.image_top': 'image-top',
  'onlineOrderingSettings.section.specialTime.allDaysClose': 'All days close',
  'onlineOrderingSettings.section.menuHour.guide': 'For Online Ordering’s Menu hour setting, please go to',
  'onlineOrderingSettings.section.autoConfirmOrders.guide':
    'For configuring auto-confirmation of online orders, please go',
  'onlineOrderingSettings.section.fee.guide': "For Online's others fee setting, please go",
  'onlineOrderingSettings.section.tips.guide': "For Online Ordering's Tips setting, please go ",
  'onlineOrderingSettings.section.tips.guide.takeOut':
    "Apply to take out orders of POS, QR, Kiosk and Call-in, for online ordering's tips setting, please go ",
  'onlineOrderingSettings.section.tips.guide.delivery':
    "Apply to delivery of call-in orders, for online ordering's tips setting, please go ",
  'onlineOrderingSettings.inputLimit': ' (Up To {maxCount} characters)',
  'onlineOrderingSettings.customTipMaxAmount': 'Customize maximum tip amount',

  'onlineOrderingSettings.action.addTime': 'Add Time',
  'onlineOrderingSettings.action.uploadBanner': 'Upload Banner',
  'onlineOrderingSettings.action.uploadImage': 'Upload Image',

  'onlineOrderingSettings.bannerUploadGuide':
    'Accepted image type: JPG, JPEG; Recommended resolution: 1312 x 196 px; Maximum file size: 5MB',
  'onlineOrderingSettings.mobile_bannerUploadGuide':
    'Accepted image type: JPG, JPEG; Recommended resolution: 1074 x 480 px; Maximum file size: 5MB',
  'onlineOrderingSettings.cropBanner': 'Crop Banner',

  'onlineOrderingSettings.low': 'Low',
  'onlineOrderingSettings.medium': 'Mid',
  'onlineOrderingSettings.high': 'High',
  'onlineOrderingSettings.default': 'Default',
  'onlineOrderingSettings.enable_dish_level_notes': 'Enable Special Instructions to Order',
  'onlineOrderingSettings.enable_zh_under_en': 'Display Foreign Name on Menu',
  'onlineOrderingSettings.enable_zh_under_en.tips':
    'The online ordering menu will display both English and the foreign language when the language is set to English',
  'onlineOrderingSettings.need_utensil': 'Enable “Need Utensil” Option',
  'onlineOrderingSettings.display_soldout': 'Display Sold-Out Dishes and Customization',
  'onlineOrderingSettings.options': 'Customized Settings',
  'onlineOrderingSettings.options.content':
    'Allow you to tailor online order experience by adjusting the following settings to enhance guest experience and streamline order customization.',
  'onlineOrderingSettings.pause_order': 'Order Acceptance',
  'onlineOrderingSettings.order_acceptance.select.option.ordering_on': 'Ordering On',
  'onlineOrderingSettings.order_acceptance.select.option.ordering_off': 'Ordering Off',
  'onlineOrderingSettings.pause_pickup_order': 'Pickup Service',
  'onlineOrderingSettings.pause_delivery_order': 'Delivery Service',
  'onlineOrderingSettings.pause_delivery_order.unavailable': 'Unavailable',
  'onlineOrderingSettings.pause_order.content':
    'Online Ordering is temporarily closed and customers will not be able to place orders.',
  'onlineOrderingSettings.sync_link_to_gfo': 'Google Food Ordering',
  'onlineOrderingSettings.sync_link_to_gfo.content':
    'Online ordering website will be automatically added to your Google business profile once the toggle is enabled. Please note that updates are generally reflected within 24 hours.',
  'onlineOrderingSettings.weekday.max.three': 'You can add up to 3 time periods per day.',
  'onlineOrderingSettings.blacklist_management.add.success': 'Add Success',
  'onlineOrderingSettings.blacklist_management.edit.success': 'Edit Success',
  'onlineOrderingSettings.blacklist_management': 'Blacklist Management',
  'onlineOrderingSettings.blacklist_management.content':
    'Add suspicious accounts to the blacklist, preventing them from placing future online orders.',
  'onlineOrderingSettings.blacklist_text': 'Blacklist',
  'onlineOrderingSettings.blacklist.list.blocked_numbers': 'Blocked Numbers',
  'onlineOrderingSettings.blacklist.list.table.column.phone_number': 'Phone Number',
  'onlineOrderingSettings.blacklist.list.table.column.reason': 'Reason',
  'onlineOrderingSettings.blacklist.list.table.column.created_at': 'Creation Time',
  'onlineOrderingSettings.blacklist.list.table.column.operator': 'Operator',
  'onlineOrderingSettings.blacklist.list.table.column.action': 'Action',
  'onlineOrderingSettings.blacklist.list.table.action.add': '+ Add',
  'onlineOrderingSettings.blacklist.list.dialog.add.title': 'Add New Phone Number',
  'onlineOrderingSettings.blacklist.list.dialog.edit.title': 'Edit Phone Number',
  'onlineOrderingSettings.blacklist.list.dialog.optional': 'Optional',
  'onlineOrderingSettings.blacklist.list.dialog.reason.fraudulent_activity': 'Fraudulent Activity',
  'onlineOrderingSettings.blacklist.list.dialog.reason.high_risk_of_no_show': 'High Risk of No Show',
  'onlineOrderingSettings.blacklist.list.dialog.reason.other': 'Other',
  'onlineOrderingSettings.blacklist.list.table.delete.confirm.title': 'Delete Confirmation',
  'onlineOrderingSettings.blacklist.list.table.delete.confirm.content':
    'Are you sure you want to delete this blacklist record?',
  'onlineOrderingSettings.blacklist.list.dialog.input.placeholder': 'example',
  'onlineOrderingSettings.blacklist.list.dialog.phone.invalid': 'Please enter an 10-digit phone number',

  'onlineOrderingSettings.self_delivery.enable_self_delivery':
    'Enable  Delivery for online ordering.  For Self Delivery and Assign Driver setting, please go to ',
  'onlineOrderingSettings.self_delivery.status': 'Status',
  'onlineOrderingSettings.self_delivery.fee': 'For Delivery Fee setting, please go to ',
  'onlineOrderingSettings.self_delivery.delivery_area': 'Delivery Area',
  'onlineOrderingSettings.self_delivery.radius': 'Radius',
  'onlineOrderingSettings.self_delivery.miles': 'miles',
  'onlineOrderingSettings.self_delivery.delivery_time': 'Delivery Time',
  'onlineOrderingSettings.self_delivery.delivery_time.mins': 'mins',
  'onlineOrderingSettings.self_delivery.delivery_instructions': 'Delivery Instructions',
  'onlineOrderingSettings.self_delivery.enable_instructions':
    'Enable instructions to your guest for delivery order . This message will be seen on the order confirmation page and in the digital receipt, not in kitchen ticket.',
  'onlineOrderingSettings.self_delivery.assign_driver': 'Assign Driver',
  'onlineOrderingSettings.self_delivery.assign_driver_content':
    'When manually finishing an order, trigger a pop-up to assign a driver upon clicking "Finish".',

  'onlineOrderingSettings.section.fee.policy': 'Delivery Fee Policy',
  'onlineOrderingSettings.section.fee.fixed': 'Fixed Delivery Fee',
  'onlineOrderingSettings.section.fee.tiered.tip': '(Set the maximum delivery range first)',
  'onlineOrderingSettings.section.fee.tiered': 'Tiered Delivery Fee',
  'onlineOrderingSettings.section.fee.tiered.table.starting_mile': 'Starting Mile',
  'onlineOrderingSettings.section.fee.tiered.table.end_mile': 'End Mile',
  'onlineOrderingSettings.section.fee.amount': 'Amount',
  'onlineOrderingSettings.section.fee.mile_gt.required': 'Starting Mile is required',
  'onlineOrderingSettings.section.fee.mile_le.required': 'End Mile is required',
  'onlineOrderingSettings.section.fee.amount.required': 'Amount required',
  'onlineOrderingSettings.section.fee.exclusive': 'exclusive',
  'onlineOrderingSettings.section.fee.inclusive': 'inclusive',
  'onlineOrderingSettings.section.fee.add.policy': 'ADD',
  'onlineOrderingSettings.section.fee.convenience_fee': 'Fees',
  'onlineOrderingSettings.section.fee.fee_setting': 'Fee Setting',

  'onlineOrderingSettings.section.endmile.valid': 'The end distance must be greater than the start distance',
  'onlineOrderingSettings.section.fee.max_mile_le': 'The maximum end distance must be greater than or equal to',
  'onlineOrderingSettings.section.tired.remove': 'Remove',
};
