export default {
  'menu.customizations.title': 'Customizations',
  'menu.customizations.groups': 'Customization Groups ({count} items)',
  'menu.customizations.options': 'Customization Options ({count} items)',
  'menu.customizations.dishPreferences': '3rd Level items({count} items)',
  'menu.customizations.group.required': 'Required',
  'menu.customizations.group.maxSelections': 'Up to {maxCount} selections',
  'menu.customizations.action.add': 'New Customization',

  'menu.customizations.edit.title': 'Edit Customizations',
  'menu.customizations.add.title': 'Add Customizations',
  'menu.customizations.section.details': 'Customized Group',
  'menu.customizations.section.options': 'Options',
  'menu.customizations.option.title': 'Option {index}',
  'menu.customizations.column.name': 'Name',
  'menu.customizations.column.foreignName': 'Foreign Name',
  'menu.customizations.column.code': 'Custom Code',
  'menu.customizations.column.required': 'Required',
  'menu.customizations.column.minSelections': 'Minimum Selections',
  'menu.customizations.errors.minSelectionsLessOrEqualThanMaxQuantities':
    'Minimum option value must be smaller or equal to maximum quantity value.',
  'menu.customizations.errors.minSelectionsLessOrEqualThanMinQuantities':
    'Minimum option value must be smaller or equal to minimum quantity value.',
  'menu.customizations.column.minQuantities': 'Minimum Quantities',
  'menu.customizations.column.maxSelections': 'Maximum Selections',
  'menu.customizations.errors.maxSelectionsGreatOrEqualThanMinSelections':
    'Maximum option cannot be smaller than minimum option.',
  'menu.customizations.column.maxQuantities': 'Max Quantities',
  'menu.customizations.errors.maxQuantitiesGreatOrEqualThanMinQuantities':
    'Maximum quantity cannot be smaller than minimum quantity.',
  'menu.customizations.errors.maxSelectionsLessOrEqualThanMaxQuantities':
    'Maximum selection cannot be greater than maximum quantity.',
  'menu.customizations.column.maxQuantitiesPerOption': 'Max Quantities Per Option',
  'menu.customizations.errors.maxQuantitiesPerOptionLessOrEqualThanMaxQuantities':
    'Maximum quantities per option cannot be greater than maximum quantity.',
  'menu.customizations.option.column.name': 'Name',
  'menu.customizations.option.column.foreignName': 'Foreign Name',
  'menu.customizations.option.column.price': 'Price',
  'menu.customizations.option.column.groupName': 'Customization Group',
  'menu.customizations.option.column.status': 'Status',
  'menu.customizations.option.column.quota': 'Quota',
  'menu.customizations.option.column.code': 'Custom Code',
  'menu.customizations.option.column.isDefault': 'Default Option',
  'menu.customizations.option.column.defaultQuantity': 'Default Quantity',
  'menu.customizations.option.isDefault': '(Default)',
  'menu.customizations.option.defaultQuantity': 'Default quantity:{defaultQuantity}',
  'menu.customizations.selectionsRestrictions': 'Selections Restrictions',
  'menu.customizations.quantityRestrictions': 'Quantity Restrictions',
  'menu.customizations.maxQuantitiesPerSection': 'Max Quantities Per Selection',
  'menu.customizations.minRestriction': 'Min',
  'menu.customizations.maxRestriction': 'Max',
  'menu.customizations.relatedDishes': 'Related Dishes ({dishCount})',

  'menu.customizations.column.name.required': 'Group name is required',
  'menu.customizations.column.foreignName.required': 'Group foreign name is required',
  'menu.customizations.option.name.required': 'Option name is required',
  'menu.customizations.option.name.existed': 'Option name is existed',
  'menu.customizations.option.foreign_name.required': 'Option foreign name is required',
  'menu.customizations.option.foreign_name.existed': 'Option foreign name is existed',
  'menu.customizations.column.defaultQuantity.required': 'Default quantity is required',
  'menu.customizations.option.defaultQuantity.exceedMaximum': 'Exceed maximum quantities per option',
  'menu.customizations.option.selected.exceedMaximum': 'Exceed maximum selections',
  'menu.customizations.option.totalQuantity.exceedMaximum':
    'Currently entered default quantities {selectedQuantity} exceeds maximum quantities {maxSelectedQuantity}',

  'menu.customizations.option.action.add': 'Add more options',
  'menu.customizations.option.action.addPreferences': 'Add preferences',

  'menu.customizations.options.count': '{count} items',

  'menu.customizations.removePrompt': 'Are you sure to delete this customization?',
  'menu.customizations.cannot.removePrompt':
    'The customization group has been set in the formula and cannot be deleted',
  'menu.customizations.cannot.removePrompt.description': 'Please uncheck the customization group in the formula first',
  'menu.customizations.cannot.removePrompt.gotcha': 'Gotcha!',

  'menu.customizations.option.column.posName': 'POS Name',
  'menu.customizations.option.column.kitchenName': 'Kitchen Name',
  'menu.customizations.option.column.posName.placeholder': 'A short name shown on the POS menu',
  'menu.customizations.option.column.kitchenName.placeholder': 'A short name shown on kitchen tickets and labels',
  'menu.customizations.option.column.posName.tooltip':
    'A shortened or abbreviated name that is case sensitive and will appear on the POS menu',
  'menu.customizations.option.column.kitchenName.tooltip':
    'A shortened or abbreviated name that is case sensitive and will appear on the kitchen tickets and labels',
  'menu.customizations.preference.title': 'Preference',
  'menu.customizations.action.choosePreferenceCategories': 'Select Preference',
  'menu.customizations.totalCount': '<b>Total:</b> {totalCount} items',
  'menu.customizations.searchPlaceholder': 'Select customized group or option',
  'menu.customizations.searchResultSummary': 'Search for',
  'menu.customizations.matchedCount': '({matchedCount} matched items)',
  'menu.customizations.chooseOption': 'Choose Option',
  'menu.customizations.selectedOptions': 'Selected Options:',
  'menu.customizations.selectedDishes': 'Selected Dishes',
  'menu.customizations.loadCustomizationDetailFailed': 'Load customization detail failed, click to retry',
  'menu.customizations.loadCustomizationListFailed': 'Load customization list failed, click to retry',
  'menu.customizations.searchCustomizationFailed': 'Search customization failed, click to retry',
  'menu.customizations.emptySearchTerm': 'Please type any search terms to find items.',
  'menu.customizations.emptySearchResult':
    'No items matched your search. Please update your search terms to find items.',
  'menu.customizations.restaurantSide': 'For Merchants',
  'menu.customizations.customerSide': 'For Customer',
  'menu.customizations.restaurantGuide': `
      <p>
        Our menu system makes customization simple and intuitive, with modifiers assigned to each item.
      </p>
      <p>
        This allows your customers to easily personalize their orders—whether it’s selecting sizes, adding toppings, or swapping ingredients.
      </p>
      <p>
        By offering these options, you enhance the customer experience while allowing your staff to process orders quickly and accurately, ensuring smooth and efficient service.
      </p>
    `,
  'menu.customizations.customerGuide': `
    <p>
      With customization, customers can easily craft the perfect order tailored to their tastes.
    </p>
    <p>
      For example, ordering bubble tea become a breeze—just choose the temperature, size, sugar level, and toppings with a few clicks.
    </p>
    <p>
      This streamlined process ensures customers get exactly what they want, every time, making the ordering experience quick and hassle-free.
    </p>
  `,
  'menu.customizations.noRelatedDishes': 'Not added to any dishes yet',
  'menu.customizations.selectionRestrictionsDescription': `For example, there are 6 options including Mayonnaise, Mustard, Ranch, Sweet onion sauce, BBQ sauce, and Ketchup, but you are limited to choosing at least 2 and at most 4.`,
  'menu.customizations.quantityRestrictionsDescription': `For example, the options include beef, chicken, onion, pepper, tomato, cucumber and other ingredients. No matter what you choose, whether the choices are repeated or not, you must choose at least 2 and at most 4.`,
  'menu.customizations.maxQuantityPerOptionDescription': `Suppose there are multiple options, and you can choose up to 2 for each option.`,
  'menu.customizations.nameConflicts': 'The name of Option {rowIndexes} conflicts',
  'menu.customizations.foreignNameConflicts': 'The foreign name of Option {rowIndexes} conflicts',
};
