export default {
  'action.ok': 'Ok',
  'action.cancel': 'Cancel',
  'action.create': 'Create',
  'action.add': 'Add',
  'action.edit': 'Edit',
  'action.delete': 'Delete',
  'action.modify': 'Save Changes',
  'action.backHome': 'Back Home',
  'action.back': 'Back',
  'action.activate': 'Activate',
  'action.deactivate': 'Deactivate',
  'action.save': 'Save',
  'action.unsave': 'Unsave',
  'action.switch': 'Assign selected item(s) to a printer',
  'action.update': 'Update',
  'action.reset': 'Reset',
  'action.confirm': 'Confirm',
  'action.export': 'Export',
  'action.exporting': 'Exporting',
  'action.refresh': 'Refresh',
  'action.fastCopy': 'Fast Copy',
  'action.refund': 'Refund',
  'action.viewReceipt': 'View Receipt',
  'action.viewSetting': 'View Settings',
  'action.next': 'Next',
  'action.apply': 'Apply',
  'action.download': 'Download',
  'action.exit': 'Exit',
  'action.selectMultiple': 'Select Multiple',
  'action.changeColor': 'Change Color',
  'action.send.text.receipt': 'Text Receipt',
  'action.send.receipt': 'Send Receipt',
  'action.send.email.receipt': 'Email Receipt',
  'action.send.receipt.cancel': 'Cancel',
  'action.send.receipt.send': 'Send',
  'action.clear': 'Clear',
  'action.clearAll': 'Clear all',
  'action.addRule': 'Add Rule',
  'action.submit': 'Submit',
  'action.addBreak': 'Add break',
  'action.close': 'Close',
  'action.connect': 'Connect',
  'action.deletePolicy': 'Delete Policy',
  'action.retry': 'Retry',
  'action.addMemberPrice': 'Add Member Price',
  'action.activateRestaurant': 'Activate Restaurant',
  'action.addPointPrice': 'Add Point Price',
  'action.scan': 'Scan',
  'action.rescan': 'Scan',
  'action.generate': 'Generate',
  'action.regenerate': 'Regenerate',
  'action.preview': 'Preview',
  'action.upload': 'Upload',
  'action.resetLoginPassword': 'Reset login password',
  'action.sort': 'Sort',
  'action.done': 'Done',
  'action.modifyName': 'Modify name',
  'action.retain': 'Retain',
  'action.notRetain': 'Not retain',
  'action.search': 'Search',
  'action.multiple': 'Multiple',
  'action.addTo': 'Add to',
  'action.removeFrom': 'Remove from',
  'action.selectAll': 'Select All',
  'action.publish': 'Publish',
  'action.duplicate': 'Duplicate',
  'action.finish': 'Finish',
};
