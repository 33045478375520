export default {
  'order.title': 'Orders',

  'order.column.order_number': 'Order #',
  'order.column.cart_number': 'Ticket #',
  'order.column.service_type': 'Service Type',
  'order.column.order_source': 'Order Source',
  'order.column.table_number': 'Table #',
  'order.column.guest_quantity': 'Guests',
  'order.column.platform': 'Platform Name',
  'order.column.status': 'Order Status',
  'order.column.employee_name': 'Server',
  'order.column.address': 'Address',
  'order.column.driver_name': 'Driver',
  'order.column.created_at': 'Order Created',
  'order.column.closed_at': 'Order Closed',
  'order.column.base_price': 'Order Total',
  'order.column.tips': 'Service Tips',
  'order.column.driver_tips': 'Delivery Tips',
  'order.column.tax': 'Tax',
  'order.column.fee_amount': 'Fees',
  'order.column.discounts': 'Discounts',
  'order.column.total': 'Order Total',
  'order.column.paid_total': 'Paid Total',
  'order.column.paid_total.tooltip': 'Total amount paid for the order.',
  'order.column.refund_total': 'Refunded Total',
  'order.column.refund_total.tooltip': 'Total amount refunded on the order.',
  'order.column.transaction_id': 'Transaction ID',
  'order.column.transaction_method': 'Payment Method',
  'order.column.operation': 'Action',
  'order.column.planned_completion_in': 'Planned Completion In',

  'order.action.addTips': 'Add Tips',
  'order.action.refund': 'Refund',
  'order.action.markAsComplete': 'Mark as Completed',

  'order.filter.search': 'Filter',
  'order.filter.dateRange': 'Search by date range',
  'order.filter.serviceType': 'Service Type',
  'order.filter.orderSource': 'Order Source',
  'order.filter.employee': 'Staff',
  'order.filter.status': 'Status',
  'order.filter.filer': 'Filter',
  'order.filterOptionNone': 'No Limit',

  'order.search.placeholder': 'Order #',
  'order.search.placeholder1': 'Order # / Ticket #',
  'order.status.placeholder': 'Select One',
  'order.placeholder.multiple': 'Multiple',

  'order.action.clear': 'Clear',
  'order.action.default': 'Default',
  'order.action.applyFilter': 'Apply Filter',
  'order.action.hide': 'Hide',
  'order.action.clearAll': 'Clear All',
  'order.action.confirmDeletion': 'Confirm Deletion of Orders',

  'order.period.start': 'No Earlier Than {time}',
  'order.period.end': 'No Later Than {time}',

  'order.selection.copy': 'Selected {count} items',
  'order.deletion.title': 'Would you like to delete {count} selected orders?',
};
