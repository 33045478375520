export default {
  'printoutSettings.title': 'Printouts Settings',
  'printoutSettings.printSettings': 'Print Settings',
  'printoutSettings.section.general': 'General',
  'printoutSettings.section.kitchen': 'Kitchen',
  'printoutSettings.section.runner': 'Runner',
  'printoutSettings.section.bill': 'Bill',
  'printoutSettings.section.receipt': 'Receipt',
  'printoutSettings.section.label': 'Label',
  'printoutSettings.section.label.tips': 'The label template is available for POS App version 3.32.1 and above.',
  'printoutSettings.section.packing': 'Packing',
  'printoutSettings.section.clockoutSummary': 'Clockout Summary',
  'printoutSettings.section.printingTaskExpirationTime': 'Printing Task Expiration Time',

  'printoutSettings.block.price': 'Shopping Cart Price',
  'printoutSettings.block.display': 'Display',
  'printoutSettings.block.general': 'General',
  'printoutSettings.block.labelTemplate': 'Label Template',
  'printoutSettings.block.basicContent': 'Basic Content',
  'printoutSettings.block.recipe': 'Recipe',
  'printoutSettings.block.fontSize': 'Font Size',
  'printoutSettings.block.style': 'Style',
  'printoutSettings.block.closeoutSummary': 'Daily Closeout Report',
  'printoutSettings.block.enlargeTheDispaly': 'Enlarge The Display',
  'printoutSettings.block.labelTopLogo': 'Label Top Logo',
  'printoutSettings.block.labelBottomSlogan': 'Label Bottom Slogan',

  'printoutSettings.field.enableRollupItemPrice': 'Roll up customization and preference prices into the dish price',
  'printoutSettings.block.price.zero.title': 'Print zero-price items',
  'printoutSettings.block.price.customizationAndPreference.zero.title': 'Print zero-price customizations & preferences',

  'printoutSettings.field.price.groupPrintPriceIncludeCustomizationAndPreference':
    'Yes -- Dish price includes customization and preference prices',
  'printoutSettings.field.price.sepatatePrintPriceForCustomizationAndPreference':
    'No -- Show customization and preference prices separately',
  'printoutSettings.field.price.zero.printPriceZeroItemWithPrice': 'Print items with price ($0.00)',
  'printoutSettings.field.price.zero.printPriceZeroItemNoRice': 'Print items without price',
  'printoutSettings.field.price.zero.notPrintPriceZeroItem': 'Do not print',

  'printoutSettings.field.price.customizationAndPreference.zero.printPriceZeroItemWithPrice':
    'Print customization & preferences with price ($0.00)',
  'printoutSettings.field.price.customizationAndPreference.zero.printPriceZeroItemNoRice':
    'Print customization & preferences without price',
  'printoutSettings.field.price.customizationAndPreference.zero.notPrintPriceZeroItem': 'Do not print',

  'printoutSettings.field.numberOfGuests': 'Number of guests',
  'printoutSettings.field.orderSource': 'Order source',
  'printoutSettings.field.customerName': 'Customer name',
  'printoutSettings.field.customerPhoneNumber': 'Customer phone number',
  'printoutSettings.field.showSeatNumber': 'Seat number',
  'printoutSettings.field.restaurantLogo': 'Restaurant logo',
  'printoutSettings.field.deliveryAddress': 'Delivery address',
  'printoutSettings.field.isDriver': 'Driver',
  'printoutSettings.field.restaurantSlogan': 'Restaurant slogan',
  'printoutSettings.field.orderNumber': 'Order number',
  'printoutSettings.field.ticketNumber': 'Ticket number',
  'printoutSettings.field.tableNumber': 'Table number',
  'printoutSettings.field.suggestedTip': 'Suggested tip',
  'printoutSettings.field.itemizedDishes': 'Itemized dishes',
  'printoutSettings.field.customerSignature': 'Customer signature',
  'printoutSettings.field.orderNote': 'Order note',
  'printoutSettings.field.showItemsThatAreZeroPrice': 'Show items that are zero-price',
  'printoutSettings.field.showPrice': 'Show price',
  'printoutSettings.field.batchNumber': 'Batch number',
  'printoutSettings.field.showCustomizationsAndPreferencesThatAreZeroPrice':
    'Show customizations & preferences that are zero-price',
  'printoutSettings.field.printCustomerCopyOfReceipt': 'Print customer copy of receipt',
  'printoutSettings.field.prices': 'Prices',
  'printoutSettings.field.time': 'Time',
  'printoutSettings.field.topSpacingRows': 'Top spacing rows',
  'printoutSettings.field.lineItemSpacingRows': 'Line item spacing rows',
  'printoutSettings.field.bottomSpacingRows': 'Bottom spacing rows',
  'printoutSettings.field.fontSize': 'Font size',
  'printoutSettings.field.printerDisplayName': 'Printer display name',
  'printoutSettings.field.printerDisplayNameOnKitcheTicket':
    'Kitchen ticket will display printer display name. To view or edit printer display name, go to {link} page.',
  'printoutSettings.field.printerDisplayNameOnPackingTicket':
    'Packing ticket will display printer display name. To view or edit printer display name, visit {link} page.',
  'printoutSettings.field.billInformation': 'Bill information',
  'printoutSettings.field.paymentStatus': 'Payment status',
  'printoutSettings.field.autoPrintWhenItemsOrOrdersVoided': 'Print runner ticket when items and orders are voided',
  'printoutSettings.packing.field.autoPrintWhenItemsOrOrdersVoided':
    'Print packing ticket when items and orders are voided',
  'printoutSettings.field.server_name': 'Server’s name',
  'printoutSettings.field.server_name.bold': 'Bold',
  'printoutSettings.field.net_sales': 'Net sales',
  'printoutSettings.field.grand_total': 'Grand total (net sales + tax)',
  'printoutSettings.field.show_order_note': 'Order note',
  'printoutSettings.field.show_dish_note': 'Dish note',
  'printoutSettings.field.printerPriceOnKitcheTicket': 'To view and edit how prices are displayed, go to {link} tab',
  'printoutSettings.field.printItemsPrice': 'Print items price',
  'printoutSettings.field.printCustomizationsAndPreferencePrice': 'Print customizations and preferences price',
  'printoutSettings.field.qrCode': 'QR code',
  'printoutSettings.field.qrCode.uploadImage': 'Upload image',
  'printoutSettings.field.qrCode.tooltip': `
    <span>Format support .jpg, .jpeg, .png, file size should be within 1M</span>
    <span>Suggest minimum size: 164 x 164</span>
  `,
  'printoutSettings.field.qrCode.tooLargeSize': 'File size needs to be 1M or smaller',
  'printoutSettings.field.qrCode.wrongFileType': 'File needs to be .jpg, .jpeg, .png',
  'printoutSettings.field.qrCode.uploadImageFailed': 'Handle image failed, please re-operate',
  'printoutSettings.field.footer': 'Footer',
  'printoutSettings.field.footer.placeholder': 'example',
  'printoutSettings.field.footer.tooltip': `
    <span>Suggest characters in one line</span>
    <span>Standard font: 20</span>
    <span>Large font: 12</span>
  `,
  'printoutSettings.field.groupDishesBySeats': 'Group dishes by seats',
  'printoutSettings.field.showKitchenName': 'Use kitchen name when printing',
  'printoutSettings.field.templateType.apply.yes': 'Applied',
  'printoutSettings.field.templateType.apply.no': 'Apply',
  'printoutSettings.field.templateType.tips.default':
    'For best results, print the QR code on 2.75 x 2.00 inch labels. Smaller sizes may cause overflow.',
  'printoutSettings.field.templateType.tips.tea':
    'Use 2.75 x 2.00 inch labels for this template. Smaller sizes may cut off content.',
  'printoutSettings.field.printFormulaRecipeCode': 'Print item codes and customization codes',
  'printoutSettings.field.printFormulaRecipeCode.tips': 'Used to guide staff in making drinks',
  'printoutSettings.field.printFormulaQrCode': 'Print QR code',
  'printoutSettings.field.printFormulaQrCode.tips':
    'Used to be recognized and make drinks by automatic equipment. We will generate the QR code in the following format, which needs to be adapted by the automatic equipment manufacturer',
  'printoutSettings.field.printFormulaQrCode.example':
    '?o=OrderNo.&equ=PickupNo.|ItemCode|Customization1Code,Customization2Code,Customization3Code|',
  'printoutSettings.field.labelLogoUrl.logo': 'LOGO',
  'printoutSettings.field.labelLogoUrl.uploadImage': 'Upload image',
  'printoutSettings.field.labelLogoUrl.tooLargeSize': 'Keep images under 1MB.',
  'printoutSettings.field.labelLogoUrl.wrongFileType': 'File needs to be JPG, JPEG, PNG',
  'printoutSettings.field.labelLogoUrl.uploadImageFailed': 'Handle image failed, please re-operate',
  'printoutSettings.field.labelLogoUrl.tips':
    'Supports JPG, JPEG, PNG image formats; max 1MB, 6:1 ratio. For optimal print quality, recommend black & white images.',
  'printoutSettings.field.labelLogoUrl.modal.title': 'Crop Label Top Logo',
  'printoutSettings.field.labelSlogan.tips':
    'Limit to 30 characters. Text may not display fully if too long. Final print prevails.',

  'printoutSettings.rulesForReceiptNumbers.title': 'Rules for receipt numbers',
  'printoutSettings.rulesForReceiptNumbers.description': `
      <div>Auto - incrementing number, the system will generate consecutive incremental serial numbers starting from the initial number.</div>
      <div>Random number, the system will generate random serial numbers within the range of 1 to 99999.</div>
    `,

  'printoutSettings.rulesForReceiptNumbers.tooltip':
    '(The new setting will take effect at the start of the next business day)',
  'printoutSettings.ticketNumberStartsFrom': 'Ticket number starts from',

  'printoutSettings.groupIdenticalDishes': 'Consolidate and print identical dishes',
  'printoutSettings.commandPrinting': 'Command Printing',
  'printoutSettings.commonPrintingExcludeLabelTicket': 'Command printing (excludes label)',
  'printoutSettings.clockoutSummaryCopy': 'Print clockout summary when employee clock out',
  'printoutSettings.enablePrintingRecoveryMode.title': 'Enable printing recovery mode',
  'printoutSettings.enablePrintingRecoveryMode.description':
    'When toggle is on, the system will automatically use other terminals to print after the local terminal fails. This only works when restaurant owns more than one terminal.',
  'printoutSettings.addTipsLayoutPreferenceForReceipt': 'Add Tips layout preference',
  'printoutSettings.addTipsLayoutPreferenceForReceipt.checkbox': 'Checkbox',
  'printoutSettings.addTipsLayoutPreferenceForReceipt.handwritten': 'Handwritten',
  'printoutSettings.printDishNameWithMappedCustomizations': 'Print dish name when customizations are mapped to printer',
  'printoutSettings.printDishNameWithMappedCustomizations.description':
    'When toggle is on, dish name will be printed in parenthesis after the customization.',
  'printoutSettings.showTextWhenCreditCardFeesAreAutoApplied': 'Show text when credit card fees are auto-applied',
  'printoutSettings.showTextWhenCreditCardFeesAreAutoApplied.description':
    'Only applies when there is a valid fee created in Adjustment Management',
  'printoutSettings.displayPaymentTotalsForCashAndCreditCard': 'Display payment totals for cash and credit card',
  'printoutSettings.highlightOpenOrders': 'Highlight open orders with a black background and white text',
  'printoutSettings.mandatorilySendAllDishedBeforePrintingBill':
    'All dishes must be delivered to the kitchen when the bill is printed',
  'printoutSettings.multiplePrintersAreAvailable': 'Manually select printer when multiple printers are available',
  'printoutSettings.byOrderSourceSales': 'By order source sales',
  'printoutSettings.byServiceTypeSales': 'By service type sales',
  'printoutSettings.byOnlinePlatformSales': 'By on platform sales',
  'printoutSettings.printCustomizationWithDish':
    'Print customizations with dish when customizations are mapped to printer',
  'printoutSettings.print_one_dish_one_print_by_meal_count':
    'Print the combined quantity of dishes separately in one-menu-one-order mode',
  'printoutSettings.printCustomizationWithDish.description':
    'When toggle is off, only unmapped customizations will print with the dish.',
  'printoutSettings.printItemsPrice.always': 'Print items with price',
  'printoutSettings.printItemsPrice.without_price': 'Print items without price',
  'printoutSettings.printItemsPrice.exclude_zero_price': 'Print items with price exclude zero-price',
  'printoutSettings.printCustomizationsAndPreferencePrice.always': 'Print customizations and preferences with price',
  'printoutSettings.printCustomizationsAndPreferencePrice.without_price':
    'Print customizations and preferences without price',
  'printoutSettings.printCustomizationsAndPreferencePrice.exclude_zero_price':
    'Print customizations and preferences with price exclude zero-price',
  'printoutSettings.field.allowSpecificItemsNotPrintOnTickets.runner':
    'Allow specific items not to be printed on the runner tickets',
  'printoutSettings.field.allowSpecificItemsNotPrintOnTickets.packing':
    'Allow specific items not to be printed on the packing tickets',
  'printoutSettings.field.allowSpecificItemsNotPrintOnTickets.table.product': 'Dishes that do not require printing',
  'printoutSettings.field.allowSpecificItemsNotPrintOnTickets.table.action': 'Action',
  'printoutSettings.field.allowSpecificItemsNotPrintOnTickets.table.add': '+ add',
  'printoutSettings.field.allowSpecificItemsNotPrintOnTickets.table.noData': 'No dish selected',
  'printoutSettings.field.allowSpecificItemsNotPrintOnTickets.modal.title.runner':
    'Select items not to be printed on the runner tickets.',
  'printoutSettings.field.allowSpecificItemsNotPrintOnTickets.modal.title.packing':
    'Select items not to be printed on the packing tickets.',
  'printoutSettings.field.allowSpecificItemsNotPrintOnTickets.modal.save': 'Save',
  'printoutSettings.field.autoByFullPaid':
    'When the Auto to Print configured as On successful transaction, the balance must be fully paid to print',
  'printoutSettings.update.success': 'Updated Printouts Settings successfully!',
  'printoutSettings.update.fail': 'Updated Printouts Settings failed!',
  'printoutSettings.printingTaskExpirationTime.never': 'Never Expires',
  'printoutSettings.printingTaskExpirationTime.interval': 'Expiration in',
  'printoutSettings.field.reviewQRCode': 'QR code for reviews',
  'printoutSettings.field.reviewQRCode.reviewsetting.link':
    'Configuration options for reviews, click <a href="/reviews/settings" target="_self">here to set it up > </a>',
};
