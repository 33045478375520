export default {
  'dishPreference.title': 'Preference Category',
  'dishPreference.field.name': 'Category Name',
  'dishPreference.field.foreign_name': 'Foreign Name',
  'dishPreference.field.option.index': 'Option {index}',
  'dishPreference.field.option.name': 'Name',
  'dishPreference.field.option.foreign_name': 'Foreign Name(optional)',
  'dishPreference.field.option.price': 'Default Price(optinal)',
  'dishPreference.field.default_custom_price': 'Default Custom Price',
  'dishPreference.field.linked_actions': 'Linked with the following actions',
  'dishPreference.field.linked_actions.description':
    'After linking this category to a specific action: the associated category will only appear when the action is performed.',
  'dishPreference.action.delete': 'Delete This Category',
  'dishPreference.action.addMore': 'Add more options',
  'dishPreference.action.addOptions': 'Add Options',
  'dishPreference.name.isRequired': 'name is required',
  'dishPreference.foreign_name.isRequired': 'foreign name is required',
  'dishPreference.name.existed': 'name is occupied',
  'dishPreference.foreign_name.existed': 'foreign name is occupied',
  'dishPreference.option.name.isRequired': 'option name is required',
  'dishPreference.option.name.existed': 'option name is existed',
  'dishPreference.option.foreign_name.existed': 'option foreign name is existed',
  'dishPreference.option.isApplied':
    'This option is attached to customized option(s), you should disconect them before deleting.',
  'dishPreference.category.isApplied':
    'This category is attached to customized option(s), you should disconect them before deleting.',
  'dishPreference.deletion.promptTitle': 'Deletion Prompt',
  'dishPreference.deletion.promptContent': 'Sure to delete this dish preference category?',

  'dishPreference.restriction.title': 'Restriction rules',
  'dishPreference.restriction.title.des':
    'It is only effective for tablet ordering & QR ordering. Other product terminals are gradually adapting. Stay tuned',
  'dishPreference.restriction.selection': 'Selection Restrictions',
  'dishPreference.restriction.selection.des':
    'For example, there are 6 options including Mayonnaise, Mustard, Ranch, Sweet onion sauce, BBQ sauce, and Ketchup, but you are limited to choosing at least 2 and at most 4.',
  'dishPreference.restriction.quantity': 'Quantity Restrictions',
  'dishPreference.restriction.quantity.des':
    'For example, the options include beef, chicken, onion, pepper, tomato, cucumber and other ingredients. No matter what you choose, whether the choices are repeated or not, you must choose at least 2 and at most 4.',
  'dishPreference.restriction.maximum': 'Maximum Quantities Per Selection',
  'dishPreference.restriction.maximum.des':
    'Suppose there are multiple options, and you can choose up to 2 for each option.',
  'dishPreference.restriction.min': 'Min',
  'dishPreference.restriction.max': 'Max',
  'dishPreference.savePrompt':
    'This option you are updating is linked to the customization option(s) listed below. Please review the list and confirm before proceeding with the updates.',
  'dishPreference.relatedCustomizations': 'Related customizations ({count})',
};
