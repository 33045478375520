export default {
  'tipSettings.title': 'Tips Settings',
  'tipSettings.tabs.general': 'General',
  'tipSettings.tabs.dine-in': 'Dine In',
  'tipSettings.tabs.take-out': 'Take Out',
  'tipSettings.tabs.delivery': 'Delivery',
  'tipSettings.enableNoTips': 'Enable “No Tips”',
  'tipSettings.enableSuggestedTipOptions': 'Enable suggested tips options',
  'tipSettings.customizeTipsOptions': 'Customize Suggested Tips Options',
  'tipSettings.suggestedTipsByPercentage': 'Suggested Tips (Percentage)',
  'tipSettings.suggestedTipsByAmount': 'Suggested Tips (Dollar Amount)',
  'tipSettings.tipOptions': 'Tip Options',
  'tipSettings.customizeTips.low': 'Low',
  'tipSettings.customizeTips.medium': 'Medium',
  'tipSettings.customizeTips.high': 'High',
  'tipSettings.percentage.required': 'Percentage is required',
  'tipSettings.defaultTip': 'Default Tip',
  'tipSettings.noTips': 'No Tips',
  'tipSettings.nullTips': 'Null',
  'tipSettings.tipOptions.showBoth': 'Show both percentages and amounts',
  'tipSettings.tipOptions.percentages_only': 'Use percentages',
  'tipSettings.tipOptions.dollars_only': 'Use dollar amount',
  'tipSettings.tipOptions.percentages_first_if_exceeds': 'Use percentages if payment exceeds',
  'tipSettings.tipOptions.percentages_first_if_exceeds.description':
    'Under this amount, show tip options in dollar amounts. Above this amount, show tip options in percentages.',
  'tipSettings.tipCalculations': 'Tip Calculations',
  'tipSettings.tipCalculations.taxes': 'Taxes',
  'tipSettings.tipCalculations.discounts': 'Discounts',
  'tipSettings.tipCalculationsByTaxes': 'Tip Calculations: Taxes',
  'tipSettings.tipCalculations.discounts.after_discount': 'After Discount',
  'tipSettings.tipCalculations.discounts.before_discount': 'Before Discount',
  'tipSettings.tipCalculations.discounts.before_discount.description': 'discount will be added back',
  'tipSettings.tipCalculationsByDiscounts': 'Tip Calculations: Discounts',
  'tipSettings.tipCalculationsAfterTax': 'Use Bill Total (after tax)',
  'tipSettings.tipCalculationsBeforeTax': 'Use Subtotal (before tax)',
  'tipSettings.tipCalculationsAfterDiscount': 'After Discount',
  'tipSettings.tipCalculationsBeforeDiscount': 'Before Discount (discount will be added back)',
  'tipSettings.discountDescription': 'Discounts include both order and dish discounts. Tax will not be re-calculated.',
  'tipSettings.calculateTipsBefore': 'Suggested tips calculation before',
  'tipSettings.calculateTipsBeforeTax': 'Tax',
  'tipSettings.calculateTipsBeforeDiscount': 'Discount',
  'tipSettings.paymentSignature': 'Require signature',
  'tipSettings.allCardPay': 'On all card payment',
  'tipSettings.exceedPay': 'If payment exceed',
  'tipSettings.collectTipsViaReceipts': 'Collect tips via receipts',
  'tipSettings.autoAppliedTipsFromFees': 'Auto-applied tips from fees',
  'tipSettings.displayAddTipssForAutoAppliedGratuity': 'Display "Add Tips" when tips have been auto-applied',
  'tipSettings.displayAddTipssForAutoAppliedGratuity.description':
    'Suggested tips will be 3%, 5%, 7% before tax, and cannot be configured.',
  'tipSettings.showMessageWhenTipAutoApplied':
    'Show text on bill when tips have been auto-applied: “Tips are already included in the bill total. Thank you for visiting!”',
  'tipSettings.maximumValueOfTips': 'Maximum tips input',
  'tipSettings.maximumValueOfTips.description': 'Tip input maximum value for customers and tip editing by staff',
  'tipSettings.remainderForTraditionalTipping':
    'Reminder for traditional tip inclusion (for orders still without tips)',
  'tipSettings.remainderForTraditionalTipping.note':
    'Note: please try to add the tip on the same business day, no later than 12:00pm of the following day.',
  'tipSettings.tippingAlertTimePoint.5pmAtTheSameDay': 'To remind once at 5:00PM on the same day',
  'tipSettings.tippingAlertTimePoint.10amAtTheNextDay': 'To remind once at 10:00AM the next day',
  'tipSettings.tippingAlertTimePoint.11amAtTheNextDay': 'To remind once at 11:00AM the next day',
  'tipSettings.remainderForTraditionalTipping.voice': 'Add an audio alert for tip adding remainder',
  'tipSettings.displayGratuity': 'Display gratuity',
  'tipSettings.showGratuityTips': 'Display tips on orders that contains gratuity',
  'tipSettings.boldGratuityTips': 'Highlight the fee reminder information',
};
