export default {
  'customerPickupScreenSettings.title': 'Customer Pickup Screen',
  'customerPickupScreenSettings.language': 'Language',
  'customerPickupScreenSettings.language.english': 'English',
  'customerPickupScreenSettings.language.englishAndChinese': 'English+Chinese(Simplified)',
  'customerPickupScreenSettings.language.chinese': 'Chinese(Simplified)',
  'customerPickupScreenSettings.headlineText': 'Headline Text',
  'customerPickupScreenSettings.headlineText.tips':
    'Customize the headline text of the customer pickup screen, and you can define different copywriting according to the business type.',
  'customerPickupScreenSettings.headlineText.inProgressStatus': 'In progress status',
  'customerPickupScreenSettings.headlineText.readyStatus': 'Ready status',
  'customerPickupScreenSettings.headlineText.english': 'English',
  'customerPickupScreenSettings.headlineText.chinese': 'Chinese',
  'customerPickupScreenSettings.screenTemplates': 'Screen Templates',
  'customerPickupScreenSettings.screenTemplates.upload': 'Upload',
  'customerPickupScreenSettings.screenTemplates.ready': 'Ready',
  'customerPickupScreenSettings.screenTemplates.inProgress': 'In Progress',
  'customerPickupScreenSettings.screenTemplates.preview': 'Preview',
  'customerPickupScreenSettings.screenTemplates.apply.yes': 'Applied',
  'customerPickupScreenSettings.screenTemplates.apply.no': 'Apply',
  'customerPickupScreenSettings.screenTemplates.upload.ads': 'Ads image',
  'customerPickupScreenSettings.screenTemplates.upload.bg': 'Bg image',
  'customerPickupScreenSettings.screenTemplates.tooltip.template1':
    'Support JPG, JPEG, GIF, PNG, limited to 3MB in size, ad image ratio 3:4, bg image ratio 1:1.',
  'customerPickupScreenSettings.screenTemplates.tooltip.template2':
    'Support JPG, JPEG, GIF, PNG, limited to 3MB in size, bg image ratio 16:9.',
  'customerPickupScreenSettings.screenTemplates.tooltip.template3':
    'Support JPG, JPEG, GIF, PNG, limited to 3MB in size, bg image ratio 16:9.',
  'customerPickupScreenSettings.screenTemplates.tooLargeSize': 'File size needs to be 3M or smaller',
  'customerPickupScreenSettings.screenTemplates.wrongFileType': 'File needs to be .jpeg, .gif, .png',
  'customerPickupScreenSettings.screenTemplates.uploadImageFailed': 'Handle image failed, please re-operate',
  'customerPickupScreenSettings.screenTemplates.templatePreview': 'Template Preview',
  'customerPickupScreenSettings.screenTemplates.require': 'Please select a screen template',
  'customerPickupScreenSettings.voiceCall': 'Voice Call',
  'customerPickupScreenSettings.voiceCall.checkbox':
    'Calling voice will be played when no. is displayed on customer pickup screen',
  'customerPickupScreenSettings.voiceCall.listen': 'Listen',
  'customerPickupScreenSettings.voiceCall.tooltip.title': 'Calling content: ',
  'customerPickupScreenSettings.voiceCall.tooltip.1':
    'Select "English" or "English+Chinese(Simplified)" as the language, and the calling voice is: "Order #Ticket no.# is ready for pickup".',
  'customerPickupScreenSettings.voiceCall.tooltip.2':
    'Select "Chinese(Simplified)" as the language, and the calling voice will play Mandarin.',
  'customerPickupScreenSettings.displayMode': 'Display Mode',
  'customerPickupScreenSettings.browserMode': 'Browser mode',
  'customerPickupScreenSettings.browserMode.tips': 'Please use the browser to access this URL on order status board.',
  'customerPickupScreenSettings.appMode': 'App mode',
  'customerPickupScreenSettings.appMode.button': 'Download the APK installation file',
  'customerPickupScreenSettings.appMode.tips':
    'Please copy the APK installation file to the Android smart device and open the file to install.',
  'customerPickupScreenSettings.callingTimes': 'Calling Times',
  'customerPickupScreenSettings.callingTimes.1': 'Call 1 time',
  'customerPickupScreenSettings.callingTimes.n': 'Call {callingTime} times',
};
