export default {
  'formula.title': 'Formula',

  'formula.guideline': 'Guideline',
  'formula.guideline.tips':
    'Formula will be printed on the label to guide staff in making drinks; or converted into a QR code to be recognized by automatic equipment.',
  'formula.guideline.step.customizations-combination': 'Customizations Combination',
  'formula.guideline.step.customizations-combination.tips':
    'Please set a code for each customization and set the order between customization groups so that the staff can know the cup type, sugar content, temperature, etc. selected by the customer when making drinks.',
  'formula.guideline.step.item-code-association': 'Item Code & Association',
  'formula.guideline.step.item-code-association.tips':
    'Set item code for automatic equipment recognition; associate customizations combination with the items, which will be printed on the label in the form of code or QR code.',
  'formula.guideline.step.print-settings': 'Print settings',
  'formula.guideline.step.print-settings.tips':
    'Set the content to be printed on the label. You can choose to print the formula as code or as QR code.',
  'formula.guideline.step.setting': 'Setting',

  'formula.customizations-combination': 'Customizations Combination',
  'formula.customizations-combination.table.search.placeholder': 'Search by Combination Name',
  'formula.customizations-combination.table.column.combination-name': 'Combination Name',
  'formula.customizations-combination.table.column.customizations-combination': 'Customizations Combination',
  'formula.customizations-combination.table.column.associated-items': 'Associated Items',
  'formula.customizations-combination.table.column.action': 'Action',
  'formula.customizations-combination.table.column.delete.title': 'Sure to delete this combination?',
  'formula.customizations-combination.form.title.create': 'Create Customizations Combination',
  'formula.customizations-combination.form.title.edit': 'Edit Customizations Combination',
  'formula.customizations-combination.form.card.tips':
    'Select customizations, set the code for each, and adjust the order between customization groups. The code for the same customization is consistent in different combinations.',
  'formula.customizations-combination.form.section.combination-name': 'Combination Name',
  'formula.customizations-combination.form.field.combination-name.placeholder': 'Please enter combination name',
  'formula.customizations-combination.form.field.combination-name.tips':
    'Combination name will not be displayed to users and is only used for management',
  'formula.customizations-combination.form.section.customizations-combination': 'Customizations Combination',
  'formula.customizations-combination.form.select-customizations.button.sort': 'Sort',
  'formula.customizations-combination.form.select-customizations.button.modify-customizations': 'Modify customizations',
  'formula.customizations-combination.form.select-customizations.button.select-customizations': 'Select customizations',
  'formula.customizations-combination.form.select-customizations.tips':
    'The same customization code is consistent in different combinations and will be updated synchronously. Please modify it with caution.',
  'formula.customizations-combination.form.select-customizations.modal.select.title': 'Select Customizations',
  'formula.customizations-combination.form.select-customizations.modal.search.placeholder':
    'Search customization groups',
  'formula.customizations-combination.form.select-customizations.modal.sort.title': 'Set Sorting',
  'formula.customizations-combination.form.select-customizations.table.column.customization-groups':
    'Customization Groups',
  'formula.customizations-combination.form.select-customizations.table.column.customizations': 'Customizations',
  'formula.customizations-combination.form.select-customizations.table.column.customization-code': 'Customization Code',
  'formula.customizations-combination.form.select-customizations.table.column.customization-code.placeholder':
    'Please enter customization code',
  'formula.customizations-combination.form.select-customizations.require': 'Please select customizations',

  'formula.item-code-association': 'Item Code & Association',
  'formula.item-code-association.meals.empty': 'No data',
  'formula.item-code-association.meals.action.associate': 'Associate',
  'formula.item-code-association.meals.action.alter': 'Alter',
  'formula.item-code-association.meals.action.disassociate': 'Disassociate',
  'formula.item-code-association.meals.action.batch-association': 'Batch Association',
  'formula.item-code-association.meals.action.batch-association.warning':
    'Some items cannot be associated with customization combination',
  'formula.item-code-association.meals.action.batch-association.warning.description':
    'Please set the customizations of the item in menu builder first',
  'formula.item-code-association.meals.action.batch-association.warning.continue': 'Continue',
  'formula.item-code-association.meals.action.batch-association.warning.back-to-modify': 'Back to modify',
  'formula.item-code-association.meals.item.item-code': 'Item Code',
  'formula.item-code-association.meals.item.item-code.placeholder': 'Please enter item code',
  'formula.item-code-association.meals.item.customizations-combination': 'Customizations Combination',
  'formula.item-code-association.meals.modal.association.title.meal': 'Select Items',
  'formula.item-code-association.meals.modal.association.title.customization': 'Associate Customizations Combination',
  'formula.item-code-association.meals.modal.association.select-all': 'Select All',
  'formula.item-code-association.meals.modal.association.select-tips': '{sum} selected',
  'formula.item-code-association.meals.save.warning': 'Some items have not yet set code or formula',
  'formula.item-code-association.meals.save.warning.description': 'Save now?',
  'formula.item-code-association.meals.save.warning.save-immediately': 'Save immediately',
  'formula.item-code-association.meals.save.warning.back-to-modify': 'Back to modify',
};
