export default {
  'salesReport.title': 'Earning Report',
  'salesReport.tab.overview': 'Overview',
  'salesReport.tab.sales': 'Sales Details',
  'salesReport.tab.service_period': 'Service Period',
  'salesReport.tab.sales_category': 'Sales Category',
  'salesReport.tab.sales_summary': 'Sales Summary',
  'salesReport.tab.periodic_trend': 'Periodic Trend',
  'salesReport.tab.section_sales': 'Section Sales',

  'salesReport.export.dailySales': 'Export Daily Sales Reports',
  'salesReport.export.trendDish': 'Export Trending Dish Reports',
  'salesReport.export.dailyPayout': 'Export Daily Payout Reports',
  'salesReport.summary': 'Sales Summary',
  'salesReport.totalItems': '{count} Items',
  'salesReport.totalOrders': '{count} Orders',

  'salesReport.summary.miscellaneous': 'Miscellaneous',
  'salesReport.totalTips': 'Total Tips',
  'salesReport.totalCardTips': 'Card Tips',
  'salesReport.totalCashTips': 'Cash Tips',
  'salesReport.totalOtherTips': 'Other Tips',
  'salesReport.averageGrossSales': 'Average Gross Sales',
  'salesReport.totalCash': 'Total Amount by Cash',
  'salesReport.totalCard': 'Total Amount by Card',
  'salesReport.totalOnline': 'Total Online Amount',
  'salesReport.totalOnlineCash': 'Total Amount by Cash',
  'salesReport.totalOthers': 'Total Others Amount',
  'salesReport.totalAvailableToPayout': 'Available to payout',
  'salesReport.totalOtherPayments': 'Total',
  'salesReport.totalDiscount': 'Discounts',
  'salesReport.totalDiscount.summary': '{percentage}% of Gross Sales',
  'salesReport.totalVoids': 'Voided',
  'salesReport.totalVoids.summary': '{voidCount} Items',
  'salesReport.totalRefunds': 'Total Refunds',
  'salesReport.summary.payment': 'Total Amount By Payment Methods',
  'salesReport.summary.type': 'By Type',
  'salesReport.totalNonTaxableFee': 'Non-taxable Fee Total',
  'salesReport.totalTaxableFee': 'Taxable Fee Total',
  'salesReport.online.summary':
    'The Gross Sales for Online Sales may or may not include markup, commissions, taxes, fees, etc. For tax reporting purpose, please refer to the monthly statement for your online platforms or Form 1099-MISC.',

  'report.column.name': 'Name',
  'report.column.date': 'Date',
  'report.column.range': 'Date Range',
  'report.column.gmv_commission': 'GMV Commission',
  'report.column.available_payout_amount': 'Payout Amount',
  'report.column.debt': 'Debt',
  'report.column.other_amount': 'Other Amount',
  'report.column.restaurant_name': 'Restaurant Name',

  'report.column.bill_count': 'Closed Orders',
  'report.column.order_count': 'Closed Orders',
  'report.column.open_order_count': 'Open Orders',
  'report.column.canceled_order_count': 'Canceled Orders',
  'report.column.payment_count': 'Payment Count',
  'report.column.item_voids_value': 'Item Voids Value',
  'report.column.voids': 'Voids Value',
  'report.column.item_voids': 'Item Voids',
  'report.column.voids_count': 'Voids Count',
  'report.column.comps': 'Comps',
  'report.column.comps_count': 'Comps Count',
  'report.column.comp_value': 'Comps Value',
  'report.column.gross_sales': 'Gross Sales',
  'report.column.avg_order_value': 'Avg Order Value',
  'report.column.avg_table_duration': 'Avg Table Duration',
  'report.column.discounts': 'Discounts',
  'report.column.discounts_rate': 'Discounts %',
  'report.column.tips': 'Service Tips',
  'report.column.staff_tips': 'Service Tips',
  'report.column.staff_tips_refunds': 'Service Tips Refunds',
  'report.column.driver_tips': 'Delivery Tips',
  'report.column.driver_tips_refunds': 'Delivery Tips Refunds',
  'report.column.commissions': 'Commission',
  'report.column.commissions_refunds': 'Commission Refunds',
  'report.column.commission': 'Commission',
  'report.column.tips_refunds': 'Tips Refund',
  'report.column.tips_rate': 'Tips %',
  'report.column.taxes': 'Taxes',
  'report.column.taxes_refunds': 'Taxes Refunds',
  'report.column.refunds': 'Refunds',
  'report.column.payment_amount_refunds': 'Payment Refunds',
  'report.column.reopen_amount_refunds': 'Reopen Orders Refunds',
  'report.column.net_refunds': 'Refunds',
  'report.column.gross_sales_refunds': 'Gross Refunds',
  'report.column.excess_payments': 'Excess Payments',
  'report.column.net_sales': 'Net Sales',
  'report.column.net_sales_refunds': 'Net Sales Refunds',
  'report.column.amount_collected': 'Paid Total',
  'report.column.amount_receivable': 'Amount Receivable',
  'report.column.cash_amount_collected': 'Cash Collected',
  'report.column.card_amount_collected': 'Card Collected',
  'report.column.online_amount_collected': 'Online Collected',
  'report.column.other_amount_collected': 'Other Collected',
  'report.column.fees_collected': 'Fees',
  'report.column.fees': 'Fees',
  'report.column.fees_refunds': 'Fees Refunds',
  'report.column.average_checks': 'Check Average',
  'report.column.adjustments': 'Adjustment',
  'report.column.deferred_sales': 'Deferred Sales',
  'report.column.deferred_sales.total': 'Total Amount',
  'report.column.deferred_sales.totalItems': '{count} Items',
  'report.column.volume': 'Volume',
  'report.section.orders': 'Orders',
  'report.section.stats': 'Statistics',
  'report.section.sale_proportion': 'The Proportion of Sales',
  'report.section.online_platform_breakdown': 'Online Platform Breakdown',
  'report.section.online_platform_sales_trend': 'Online Platform Sales Trend',
  'report.order.summary': '{count} orders',
  'report.order.total_summary': 'Total Orders',
  'report.description.gross_sales':
    'Gross Sales is the sum of all order subtotals, and does not include commissions, taxes, tips, fees, and adjustments. Discounts and refunds do not affect gross sales.',
  'report.description.net_sales': '<b>Net sales</b> is the gross sales subtract discounts.',
  'report.description.amount_collected':
    '<b>Amount Receivable</b> is the sum of online amount collected, cash amount collected, and card amount collected.<br /><b>The online amount collected</b> is an estimate revenue based on online orders collected via Chowbus POS. The final amount varies according to taxes and fees each online platforms charge.',
  'report.description.online_amount_collected':
    'Online collected is an estimated revenue based on online orders collected via Chowbus POS. The final amount varies according to taxes and fees each online platforms charge.',
  'report.description.average_checks':
    'This number is calculated based on the total amount customer(s) paid on In-Person orders and Call-In orders.',
  'report.description.tip_rate': 'Tips percentage is calculated based on {base}.',
  'report.description.discount_rate': 'Discounts is calculated based on Subtotal (before tax) divided by Gross Sales.',
  'report.description.canceled_orders': 'Canceled Orders are not included in Sales numbers.',
  'report.placeholder.filter.order_source': 'Filter by Order Source',
  'report.placeholder.filter.employee': 'Filter by Employee, Multiple',
  'report.column.feesAndAdjustment': 'Fees & Adjustment',
  'report.column.availabelToPayoutAmount': 'Available to Payout Amount',
  'report.column.fix_rate': 'Fix Rate Fee',
  'report.column.saas_fee': 'Saas Fee',
  'report.column.income_adjustment': 'Adjustment',
  'report.column.sales_payout_amount': 'Available to Payout Sales Amount',
  'report.column.summary': 'Summary',
  'report.column.net_cash': 'Net Cash',
  'report.column.dine_in_guests': 'Dine-In Guests',
  'report.payment.count': 'Count',
  'report.payment.amount': 'Amount',
  'report.payment.tips': 'Tips',
  'report.payment.total': 'Total',

  'salesReport.section.byServiceType': 'By Service Type',
  'salesReport.section.byOrderSource': 'By Order Source',
  'salesReport.section.salesTrends': 'Sales Trends',
  'salesReport.section.salesByMenu': 'Menu',
  'salesReport.section.salesByCategory': 'Categories',
  'salesReport.section.salesByDish': 'Dishes & Customizations Breakdown',
  'salesReport.section.salesBreakdown': 'Sales Breakdown',
  'salesReport.section.salesByCustomizations': 'Customizations',
  'salesReport.section.dailySales': 'Daily Sales',
  'salesReport.section.byPlatform': 'By Platform',
  'salesReport.section.salesTrendsByPlatform': 'Sales Trends by Platform',
  'salesReport.section.availableToPayout': 'Available to Payout',
  'salesReport.section.deferredSales': 'Deferred Sales Amount',

  'salesReport.breakout.fees': 'Total Fees',
  'salesReport.breakout.tips': 'Total Tips',
  'salesReport.breakout.commissions': 'Total Commission',
  'salesReport.breakout.taxes': 'Total Taxes',
  'salesReport.breakout.discounts': 'Total Discounts',

  'salesReport.summary.dailySales': 'Sales Summary',
  'salesReport.summary.payoutByDate': 'Payout Summary',
  'salesReport.summary.restaurantsSales': 'Restaurants Sales Summary',

  'report.filter.menu': 'Filter by Menu',
  'report.filter.category': 'Filter by Category',
  'report.filter.dish': 'Filter by Dish',
  'report.column.category_name': 'Category',
  'report.column.dish_count': 'Count',
  'report.column.dish_name': 'Dish',
  'report.column.customization_name': 'Customization Name',
  'report.column.menu_name': 'Menu Name',

  'report.sheet.salesByMenu': 'Sales by Menu',
  'report.sheet.salesByCategory': 'Sales by Category',
  'report.sheet.salesByDish': 'Sales by Dish & Customizations',
  'report.sheet.salesByCustomizations': 'Sales by Customizations',

  'report.dishType.dishes': 'Dishes',
  'report.dishType.rewards': 'Rewards',

  'salesReport.collectSummary.totalAmountBeforeRefund': 'Total amount without Refund',
  'salesReport.collectSummary.totalRefund': 'Refund total',
  'salesReport.collectSummary.totalAmountAfterRefund': 'Total amount',
  'salesReport.refundAmount.card': 'Refund amount by card',
  'salesReport.refundAmount.cash': 'Refund amount by cash',
  'salesReport.refundAmount.other': 'Refund amount by others',
  'salesReport.refundAmount.online': 'Refund amount by online',

  'salesReport.salesCategory.table.title': 'Sales by Sales Categroy',
  'salesReport.salesCategory.select.label': 'Sales Categroy break into',
  'salesReport.salesCategory.button.update': 'update',
};
