export default {
  'menuDecorate.title': 'Tablet Pro',
  'menuDecorate.dishSelector.title': 'Dish Selector',
  'menuDecorate.description':
    'Please use iMin T01 or Amazon Fire HD 10 devices. Other devices are not adapted and the page may be distorted.',
  'menuDecorate.enableMode': 'Enable this mode',
  'menuDecoration.mode.landscape': 'Landscape Mode',
  'menuDecoration.mode.portrait': 'Portrait Mode',
  'menuDecoration.mode.default': 'No Workshop',
  'menuDecoration.wrongEntrace':
    'Please enter this page from the entrace “Smart Ordering > Tablet Ordering > Tablet Pro”',
  'menuDecorate.colorAndTheme': 'Color & Theme',
  'menuDecorate.action.startEdit': 'Start Customizing',
  'menuDecorate.action.preview': 'Preview',
  'menuDecorate.action.exitEdit': 'Exit edit',
  'menuDecorate.action.saveAndExitEdit': 'Save & Exit edit',
  'menuDecorate.action.addPage': 'Add page',
  'menuDecorate.action.changeImage': 'Change Image',
  'menuDecorate.action.addDishLink': 'Add dish link',
  'menuDecorate.action.duplicatedComponent': 'Duplicate component',
  'menuDecorate.colorAndTheme.title': 'Color Theme',
  'menuDecorate.noItems': 'Please add dish link',
  'menuDecorate.chooseCategory': 'Choose Category',
  'menuDecorate.component.column.enableBackgroundFill': 'Background fill',
  'menuDecorate.component.column.backgroundColor': 'Background color',
  'menuDecorate.component.column.enableBackgroundStroke': 'Background stroke',
  'menuDecorate.component.column.backgroundStrokeColor': 'Stroke color',
  'menuDecorate.component.section.componentLayout': 'Component Layout',
  'menuDecorate.component.column.showDishNname': 'Dish name',
  'menuDecorate.component.column.fontFamily': 'Font',
  'menuDecorate.component.column.cnFontFamily': 'CN font',
  'menuDecorate.component.column.enFontFamily': 'EN font',
  'menuDecorate.component.column.fontColor': 'Font color',
  'menuDecorate.component.column.fontSize': 'Font size',
  'menuDecorate.component.column.cnFontSize': 'CN font size',
  'menuDecorate.component.column.enFontSize': 'EN font size',
  'menuDecorate.component.column.showDishInfo': 'Dish info',
  'menuDecorate.component.column.showButton': 'Button',
  'menuDecorate.component.column.buttonStyle': 'Button style',
  'menuDecorate.component.column.buttonStyle.regular': 'Regular',
  'menuDecorate.component.column.buttonStyle.transparent': 'Transparent',
  'menuDecorate.component.column.buttonBackgroundColor': 'Button background color',
  'menuDecorate.component.column.buttonContentColor': 'Button content color',
  'menuDecorate.component.column.buttonSize': 'Button size',
  'menuDecorate.component.section.dishLinkSetting': 'Dish link setting',
  'menuDecorate.component.action.showMoreLayouts': 'Show more layouts',
  'menuDecorate.component.action.hideMoreLayouts': 'Hide more layouts',
  'menuDecorate.uploadImage': 'Upload image',
  'menuDecorate.imageRequest': 'Image request:',
  'menuDecorate.imageUploadGuide':
    'Supported formats are .jpg .jpeg .png Each image is limited to 4MB in size. Size should be {width} x {height}.',
  'menuDecorate.imageUploadGuide.sizeNotMatched': 'Size should be {width} x {height}',
  'menuDecorate.imageUploadGuide.formatNotMatched': 'The format should be .jpg, .jpeg, .png',
  'menuDecorate.imageUploadGuide.sizeTooLarge': 'The image is limited to 4MB in size',
  'menuDecorate.deletePromptTitle': 'Are you sure you want to delete this page?',
  'menuDecorate.deletePromptContent': 'Deleting this page will remove any related customized content.',
  'menuDecorate.savePromptTitle': 'Do you want to save the edited content?',
  'menuDecorate.savePromptContent': 'There are unsaved changes on the current page. Please save them first.',
  'menuDecorate.changeBackgroundPromptTitle': 'Do you want to keep the dish links?',
  'menuDecorate.changeBackgroundPromptContent':
    'Should the current dish link still be retained after the image is updated?',
  'menuDecorate.saveSuccess': 'Successfully saved Tablet Pro settings',
  'menuDecorate.saveOnErrors.no_background_image.title': 'No picture uploaded',
  'menuDecorate.saveOnErrors.no_background_image.description':
    'The page has not uploaded any picture. Please upload the picture before saving.',
  'menuDecorate.saveOnErrors.hotspot_without_meal.title': 'Components without dishes',
  'menuDecorate.saveOnErrors.hotspot_without_meal.description':
    'There are components that have not yet been associated with dishes. Please modify them before saving.',
  'menuDecorate.saveOnErrors.hotspots_overlaps.title': 'Components overlapping',
  'menuDecorate.saveOnErrors.hotspots_overlaps.description':
    'There are some components that are overlapping. To avoid accidental clicks, please modify them before saving.',
  'menuDecorate.addedToCurrentPage': 'Added to current page',
  'menuDecorate.currentSelection': 'Currently linked dish',
  'menuDecorate.backToModify': 'Back to modify',
  'menuDecorate.saveImmediately': 'Save immediately',
  'menuDecorate.reserveHotspots.title': 'Do you want to keep the dish link(s)?',
  'menuDecorate.reserveHotspots.description':
    'Should the current dish link(s) still be retained after the image is updated?',
  'menuDecorate.unDecoratedMenus.title': 'Some menus have not yet configured any dishes. ',
  'menuDecorate.unDecoratedMenus.description':
    '{menuNames} have not configured any dishes. Would you like to save now?',
  'menuDecorate.cannotPublishMenuDecoration':
    'You cannot switch decoration mode due to the permission(publish menu decoration)',
  'menuDecorate.isMember': 'Member',
  'menuDecorate.points': 'pts',
  'menuDecorate.compSize': 'Comp Size',
  'menuDecorate.compWidth': 'W',
  'menuDecorate.compHeight': 'H',
};
