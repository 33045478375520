export default {
  'menuBuilder.menus': 'Menus',
  'menuBuilder.menuBuilder': 'Menu Builder',
  'menuBuilder.resourceAction.top.search': 'Search Result',
  'menuBuilder.resourceAction.contract.publish': 'Publish Menu',
  'menuBuilder.resourceAction.menu.edit': 'Menu Info',
  'menuBuilder.resourceAction.meal.add': 'New Dish',
  'menuBuilder.resourceAction.meal.add_existing': 'Add Existing',
  'menuBuilder.resourceAction.meal.edit': 'Edit Dish',

  'menuBuilder.column.dish': 'Dish',
  'menuBuilder.column.menuAndCategory': 'Menu & Category',
  'menuBuilder.column.display_color': 'Color',
  'menuBuilder.column.price': 'Price',
  'menuBuilder.column.open_price': 'Open Price',
  'menuBuilder.column.point_price': 'Point Price',
  'menuBuilder.column.member_price': 'Member Price',
  'menuBuilder.column.discount_exempt': 'Discount',
  'menuBuilder.column.tax_exempt': 'No-tax',
  'menuBuilder.column.tax_code': 'Tax Code & Rate',
  'menuBuilder.column.tax_rate': 'Rate: ',
  'menuBuilder.column.customization': 'Customization',
  'menuBuilder.column.code': 'Custom Code',
  'menuBuilder.column.name': 'Name',
  'menuBuilder.column.foreign_name': 'Foreign Name',
  'menuBuilder.column.available_time': 'Available Time',
  'menuBuilder.column.specifal_time': 'Special Time',
  'menuBuilder.errors.start_at_required': 'Start time is required',
  'menuBuilder.errors.end_at_required': 'End time is required',
  'menuBuilder.errors.start_at_after_end_at': 'Start time should before end time.',
  'menuBuilder.errors.menu_hours_conflict': 'Hours cannot overlap',
  'menuBuilder.requriedSpecialDate': 'Date of is required',
  'menuBuilder.rowConflicts': 'The time of item {i} conflicts with item {j}',
  'menuBuilder.maxHourSettingsPerDay': 'You can add up to {maxCount} time periods per day.',
  'menuBuilder.same_as_businesshour': 'Same as Business Hour',
  'menuBuilder.different_business_hours': 'Different from Business Hour',
  'menuBuilder.pastMidnight': 'Past Midnight',

  'menuBuilder.segment.search': 'Search',
  'menuBuilder.segment.selected': 'Edit Selected Dishes',

  'menuBuilder.action.sort': 'Sort',
  'menuBuilder.action.updateMultiple': 'Update Multiple',
  'menuBuilder.action.updateMultiple.pricing': 'Pricing',
  'menuBuilder.action.updateMultiple.memberPrice': 'Member Price',
  'menuBuilder.action.updateMultiple.discount': 'Discount',
  'menuBuilder.action.updateMultiple.taxSettings': 'Tax Settings',
  'menuBuilder.action.updateMainImage': 'Upload main image',
  'menuBuilder.action.update3rdImage': 'Upload 3rd image',
  'menuBuilder.action.dishSorting': 'Dish Sorting',
  'menuBuilder.action.addMore': 'Add More',
  'menuBuilder.action.setColor': 'Set Color',
  'menuBuilder.action.addTime': 'Add Special Hours',
  'menuBuilder.action.showDetail': 'See detail',
  'menuBuilder.action.close': 'Close',
  'menuBuilder.action.dishSort': 'Dish Sorting',
  'menuBuilder.action.showMore': 'Show {count} more',
  'menuBuilder.action.hideMore': 'Hide {count} more',
  'menuBuilder.action.publishMenu': 'Publish Menu',
  'menuBuilder.action.showMoreMenus': 'Show More',
  'menuBuilder.action.hideMoreMenus': 'Hide More',

  'menuBuilder.allDaysClose': 'All Day Close',
  'menuBuilder.guideText': 'Select a category within this menu to start organizing dishes',
  'menuBuilder.searchPlaceholder': 'Find an existing dish',
  'menuBuilder.searchEmptyDescription': 'Search and select all the existing dishes you want to offer in this category.',
  'menuBuilder.selectedMealsReachedTheLimit':
    'You can select up to <span style="color: #ce2c54;font-size: 14px;font-family: PingFang SC;">{maxCount} items</span> at a time. Please cancel other selections and try again.',
  'menuBuilder.searchMealsPlaceholder': 'Search items',

  'menuBuilder.section.dishInfo': 'Dish Info',
  'menuBuilder.section.printers': 'Printers',
  'menuBuilder.section.taxCode': 'Tax Code',
  'menuBuilder.selectedCategories': 'Selected categories:',
  'menuBuilder.deleteMealPrompt': 'Sure to delete this dish?',
  'menuBuilder.sortDish.title': 'Set Sort',
  'menuBuilder.sortBy.price': 'Price',
  'menuBuilder.sortBy.alpha': 'Alpha Sorting',
  'menuBuilder.menuHourMode.always': 'Always',
  'menuBuilder.menuHourMode.specific': 'Specific days and time',
  'menuBuilder.searchGuideText': 'Search and select all the existing dishes you want to offer in this category.',
  'menuBuilder.emptyResultText': 'No items match your search',
  'menuBuilder.emptyResultGuide': 'Try updating or clearing your search to find items',
  'menuBuilder.loadMenuFailed': 'Load failed, retry',
  'menuBuilder.noData': 'No data',
  'menuBuilder.customizationPickerDrawerTitle': 'Choose Customizations',
  'menuBuilder.selectAll': 'Select All',
  'menuBuilder.customizationsDescription': `
    The <span style="color: #ce2c54;font-family: SimSun, sans-serif;">*</span> by the customization group indicates it is required that the customer choose at least a customization option when ordering this dish. If you would like to change this, please visit the Customizations page.
  `,
  'menuBuilder.categoriesRequired': 'Please pick at least one category in any menu.',
  'menuBuilder.noMealItems': 'Let’s add new items to this menu category',
  'menuBuilder.pointsCategory': 'Points',
  'menuBuilder.dishAdded': 'Added',
  'menuBuilder.successfullyCreated': 'Successfully created dishes',
  'menuBuilder.successfullyUpdated': 'Successfully updated dishes',
  'menuBuilder.successfullyUpdatedMenu': 'Successfully updated menu info',
  'memuBuilder.taxable': 'Taxable',

  'menuBuilder.emptySearchResult': 'No items matched your search. Please update your search terms to find items.',
  'menuBuilder.tooManySearchResult':
    'Your search for “<span>{searchTerm}</span>” returned more than <span>50</span> items. Please refine your search terms to obtain more specific results.',
  'menuBuilder.emptySearchTerm': 'Please type any search terms to find items.',
  'menuBuilder.noCustomizations': 'No customizations',
  'menuBuilder.searchCustomizations': 'Search items',
  'menuBuilder.searchCategories': 'Search categories',

  'menuBuilder.channelVisibility': 'Channel Visibility',
  'menuBuilder.channelVisibility.description': 'The menu will display on the channel enabled below.',
  'menuBuilder.channel.qr': 'QR',
  'menuBuilder.channel.kiosk': 'Kiosk',
  'menuBuilder.channel.tablet': 'Tablet',
  'menuBuilder.menuDetails': 'Menu Details',
  'menuBuilder.menuAvailability': 'Menu Availability',
  'menuBuilder.menuAvailability.description': 'Determines when the menu is available for ordering.',
  'menuBuilder.menuAvailability.always': 'Always',
  'menuBuilder.menuAvailability.specific_days_and_time': 'Specific days and time',
  'menuBuilder.menuAvailability.menuHours': 'Menu Hours',
  'menuBuilder.menuAvailability.availableAfterHours': 'Available After Hours',
  'menuBuilder.menuAvailability.availableAfterHours.description':
    'Defines whether the menu is accessible outside the menu hours added above; if available, it will be visible for browsing only.',
  'menuBuilder.specialHours.description':
    'The special hours added for specific day(s) will override the regular menu hour designed above.',

  // 合约简介
  'menuBuilder.contractDescription.pos':
    'Menus in this section are instantly synced to your POS terminal, allowing staff to easily navigate organized categories on screen. Add items, make substitutions, and handle special requests with just a few clicks—streamlining order flow and enhancing the customer experience!',
  'menuBuilder.contractDescription.smart_ordering':
    'Menus in this section will be applied to your Smart Ordering channels, including Kiosks, QR code, and Tablets. All menu details—such as photos, descriptions, prices, and customizations—are directly displayed to customers, providing an enhanced dining experience.',
  'menuBuilder.contractDescription.third_party':
    'Menus in this section are synced with third-party delivery platforms. Manage and update all your delivery menus easily from a single, user-friendly menu builder. Any changes you make will be automatically reflected across all platforms once published—saving you time and effort!',
  'menuBuilder.contractDescription.white_label':
    'Menus in this section are instantly synced to your website, ensuring customers always have access to the latest updates. Fully optimized and easy to navigate, the menu displays all the item details and special menu hours clearly, delivering a seamless online ordering experience.',
  // 合约未开启时的提示
  'menuBuilder.contractInactive.pos':
    'A powerful and user-friendly POS terminal simplifies table and order management—creating a seamless and efficient ecosystem for your business!',
  'menuBuilder.contractInactive.smart_ordering':
    'Offer three contactless ordering and payment solutions for different scenarios—engaging customers and reducing labor costs!',
  'menuBuilder.contractInactive.third_party':
    'Manage third-party delivery platform menus and orders directly from your POS and Dashboard—eliminating tablets and minimizing errors!',
  'menuBuilder.contractInactive.white_label':
    'Let your customers order easily from your website—no commission fees, boost your Google visibility, and drive more sales directly to your store!',
  'menuBuilder.publishMenu.title': 'Publish Menu',
  'menuBuilder.publishMenu.description':
    'Select a menu and publish it to the platform. You can publish a single menu across multiple or all platforms; however, each platform can only accept one menu.',
  'menuBuilder.publishHistory.title': 'Menu Publish History',
  'menuBuilder.publishHistory.description':
    'View the menu publish history by selecting the respective platforms. Please allow a few minutes for the request to be processed on the platforms. If the menu fails to publish, kindly refer to the failure reason provided below for further details.',
  'menuBuilder.integratedDeliveryPlatforms': 'Integrated Delivery Platforms',
  'menuBuilder.menuCategory.title': 'Category',
  'menuBuilder.menuCategory.dishCount': 'Total Dishes',
  'menuBuilder.menuCategory.underConstruction': 'Under construction',
  'menuBuilder.menuPublish.selectMenuPlaceholder': 'Select A Menu',
  // 菜单发布记录
  'menuBuilder.publishHistory.column.date': 'Date',
  'menuBuilder.publishHistory.column.platform': 'Platform',
  'menuBuilder.publishHistory.column.menu': 'Menu',
  'menuBuilder.publishHistory.column.operator': 'Operator',
  'menuBuilder.publishHistory.column.status': 'Status',
  'menuBuilder.publishHistory.searchPlaceholder': 'Select Platforms',
  'menuBuilder.publishMenuStatus.success': 'Success',
  'menuBuilder.publishMenuStatus.failed': 'Failed',
  'menuBuilder.publishMenuStatus.in_progress': 'In Progress',
  // Online ordering 设置
  'menuBuilder.onlineOrderingSettings.delivery.title': 'Delivery',
  'menuBuilder.onlineOrderingSettings.delivery.description':
    'Provide customers with delivery service and keep 100% of the profits—no more extra commission fees.',
  'menuBuilder.onlineOrderingSettings.pickup.title': 'Pickup',
  'menuBuilder.onlineOrderingSettings.pickup.description':
    'Customers can easily order in advance on your website and pick up their order at a convenient time—a flexible ordering experience.',
  'menuBuilder.onlineOrderingSettings.googleFoodOrdering.title': 'Google Food Ordering',
  'menuBuilder.onlineOrderingSettings.googleFoodOrdering.description':
    'Google Food Ordering boosts your restaurant’s visibility on Google Search and Maps, making it easier for customers to find you and place orders directly—bringing more traffic and orders straight to your door!',
  'menuBuilder.loadChannelsFailed': 'Faild to load delivery platforms, click to retry',
  'menuBuilder.unbindChannels': 'No platform has been set up yet',
  // 菜单相关资源
  'menuBuilder.resource.menu': 'Menu',
  'menuBuilder.resource.category': 'Category',
  'menuBuilder.resource.dish': 'Dish',
  // 菜单相关字段
  'menuBuilder.menu.totalDish': 'Total',
  'menuBuilder.menu.availableDish': 'Available',
  'menuBuilder.menu.soldOutDish': 'Sold Out',
  'menuBuilder.menu.menuHoursOfToday': 'Today’s hour',
  'menuBuilder.menu.allDayAvailable': 'All Day',
  'menuBuilder.menu.allDayClose': 'Closed',
};
