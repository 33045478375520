export default {
  'kitchenDisplayScreenSettings.title': 'Kitchen Display Screen',

  'kitchenDisplayScreenSettings.virtual-kds.table.title':
    'A virtual Kitchen Display Screen is a virtual device that can save settings in cloud and be mapped to multiple physical devices.',
  'kitchenDisplayScreenSettings.virtual-kds.table.create': '+ Create a new Virtual KDS',
  'kitchenDisplayScreenSettings.virtual-kds.table.column.name': 'Name',
  'kitchenDisplayScreenSettings.virtual-kds.table.column.service-type': 'Service Type',
  'kitchenDisplayScreenSettings.virtual-kds.table.column.usage': 'Usage',
  'kitchenDisplayScreenSettings.virtual-kds.table.column.display-mode': 'Display Mode',
  'kitchenDisplayScreenSettings.virtual-kds.table.column.mapped-screens': 'Mapped Screens',
  'kitchenDisplayScreenSettings.virtual-kds.table.column.mapped-screens.tips':
    'Number of screens mapped to this virtual station',
  'kitchenDisplayScreenSettings.virtual-kds.table.column.auto-print': 'Auto Print',
  'kitchenDisplayScreenSettings.virtual-kds.table.column.action': 'Action',
  'kitchenDisplayScreenSettings.virtual-kds.form.title.create': 'Create a new virtual display screen',
  'kitchenDisplayScreenSettings.virtual-kds.form.title.edit': 'Edit Virtual KDS',
  'kitchenDisplayScreenSettings.virtual-kds.form.exit.title': 'Are you sure you want to exit?',
  'kitchenDisplayScreenSettings.virtual-kds.form.exit.description':
    "If you exit now, your setup will be lost, and you'll need to start over.",
  'kitchenDisplayScreenSettings.virtual-kds.form.section.step.1': 'Step 1 of 3',
  'kitchenDisplayScreenSettings.virtual-kds.form.section.step.2': 'Step 2 of 3',
  'kitchenDisplayScreenSettings.virtual-kds.form.section.step.3': 'Step 3 of 3',
  'kitchenDisplayScreenSettings.virtual-kds.form.coming-soon': 'Coming Soon',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.name.long': 'Please name of this virtual display screen',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.name.short': 'Name',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.name.placeholder':
    'Please enter the name of this virtual display screen',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.role': 'What are you going to use this display screen for?',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.role.runner-display': 'Runner Display',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.role.runner-display.tips':
    'This is used for marking orders or dishes as completed before running foods to tables.',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.role.cooking-display': 'Cooking Display',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.role.cooking-display.tips':
    'This is used for cooking work. It receives items to be prepared and shows to the kitchen staffs.',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.group-style':
    'Select a display mode for this virtual display screen',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.group-style.order-based': 'Order-Based',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.group-style.order-based.tips':
    'Each order is displayed separately, providing a complete overview.',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.group-style.show-each-dish-separately':
    'Show Each Dish Separately',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.group-style.show-each-dish-separately.tips':
    'Each dish is displayed separately, making it easy for the kitchen to see individual preparation needs.',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.group-style.combine-same-dishes':
    'Combine Same Dishes',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.group-style.combine-same-dishes.tips':
    'Same dishes are grouped together with a total number, enabling batch cooking.',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.view-style':
    'Select a view for this virtual display screen',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.view-style.dynamic-view': 'Dynamic View',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.view-style.dynamic-view.tips':
    'Orders are stocked dynamically.',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.view-style.fixed-view': 'Fixed View',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.view-style.fixed-view.tips':
    'Each order will be displayed in one column.',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.show-kds-summary-on-left':
    'Do you want to show the dish summary on the screen?',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.show-kds-summary-on-left.yes': 'Yes',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.show-kds-summary-on-left.yes.tips':
    "The dish summary will be shown on the left side, providing a quick overview for each dish's quantity.",
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.show-kds-summary-on-left.no': 'No',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.display-mode.show-kds-summary-on-left.no.tips':
    'Hide the dish summary.',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.service-types': 'Select Service Type',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.service-types.placeholder': 'Service Type',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.service-types.tips':
    'Filter order by service type, ensuring only relevant orders are displayed.',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.kds-meal-prototypes': 'Select dishes to display',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.kds-meal-prototypes.tips':
    'Select specific dishes to display, allowing you to focus on only the necessary items.',
  'kitchenDisplayScreenSettings.virtual-kds.form.field.kds-meal-prototypes.select-all': 'Select All',
  'kitchenDisplayScreenSettings.virtual-kds.form.modal.success.title':
    "Congratulations! You've finished all settings for this virtual display screen.",
  'kitchenDisplayScreenSettings.virtual-kds.form.modal.success.description':
    'Image for reference only. Please select and map the Virtual KDS on your hardware device (as shown).',
  'kitchenDisplayScreenSettings.virtual-kds.form.modal.success.img.tips': 'Image for reference only',

  'kitchenDisplayScreenSettings.timeout-alert.field.order-timeout-thresholds': 'Order Timeout Thresholds',
  'kitchenDisplayScreenSettings.timeout-alert.field.order-timeout-thresholds.tips':
    'Define L1 and L2 thresholds for all orders. Once an order reaches L1 or L2, it is highlighted with the corresponding colors.',
  'kitchenDisplayScreenSettings.timeout-alert.field.order-timeout-thresholds.name': 'Name',
  'kitchenDisplayScreenSettings.timeout-alert.field.order-timeout-thresholds.name.1': 'Level 1',
  'kitchenDisplayScreenSettings.timeout-alert.field.order-timeout-thresholds.name.2': 'Level 2',
  'kitchenDisplayScreenSettings.timeout-alert.field.order-timeout-thresholds.enabled': 'Enabled',
  'kitchenDisplayScreenSettings.timeout-alert.field.order-timeout-thresholds.thresholds': 'Thresholds (mintues)',
  'kitchenDisplayScreenSettings.timeout-alert.field.order-timeout-thresholds.thresholds.tips':
    'Once the order time exceeds a level, it will be highlighted with the corresponding color.',
  'kitchenDisplayScreenSettings.timeout-alert.field.order-timeout-thresholds.thresholds.mintues': 'mintues',
  'kitchenDisplayScreenSettings.timeout-alert.field.order-timeout-thresholds.thresholds.error':
    'Level 2 timeout threshold must be greater than Level 1.',
  'kitchenDisplayScreenSettings.timeout-alert.field.order-timeout-thresholds.color-preview': 'Color Preview',
  'kitchenDisplayScreenSettings.timeout-alert.field.order-timeout-thresholds.color-preview.tips':
    'Appears when any order exceeds the threshold L1 or L2.',
  'kitchenDisplayScreenSettings.timeout-alert.field.order-timeout-thresholds.color-preview.delivery': 'Delivery',

  'kitchenDisplayScreenSettings.general-settings.section.master-device-id': 'KDS Server Selection',
  'kitchenDisplayScreenSettings.general-settings.field.master-device-id': 'Select a hardware device as your KDS Server',
  'kitchenDisplayScreenSettings.general-settings.field.master-device-id.tips.title': 'Guidelines for KDS Server',
  'kitchenDisplayScreenSettings.general-settings.field.master-device-id.tips.content.1':
    '1. Place the KDS server where power and network are reliable.',
  'kitchenDisplayScreenSettings.general-settings.field.master-device-id.tips.content.2':
    '2. Use a wired connection and reserve a static IP if possible.',
  'kitchenDisplayScreenSettings.general-settings.field.master-device-id.tips.content.3':
    '3. Don\'t forget to click "Save" to apply changes.',
  'kitchenDisplayScreenSettings.general-settings.field.master-device-id.tips.content.4':
    '4. All KDS devices need a few minutes to initialize after the new KDS server is saved.',
  'kitchenDisplayScreenSettings.general-settings.field.master-device-id.tips.content.5':
    '5. Make sure the selected device is currently powered on and online. Any downtime might disrupt all KDS.',
  'kitchenDisplayScreenSettings.general-settings.field.master-device-id.table.column.device-name': 'Device Name',
  'kitchenDisplayScreenSettings.general-settings.field.master-device-id.table.column.serial-number': 'Serial Number',
  'kitchenDisplayScreenSettings.general-settings.field.master-device-id.table.column.last-heartbeat-time':
    'Last HeartBeat Time',
  'kitchenDisplayScreenSettings.general-settings.field.master-device-id.table.column.mapped-virtual-kds':
    'Mapped Virtual KDS',
  'kitchenDisplayScreenSettings.virtual-kds.form.modal.kds-server-updated.title': 'KDS Server Updated',
  'kitchenDisplayScreenSettings.virtual-kds.form.modal.kds-server-updated.description.1':
    'All KDS will start syncing with the new server. This process may take a few minutes.',
  'kitchenDisplayScreenSettings.virtual-kds.form.modal.kds-server-updated.description.2':
    'Previous and new orders will appear after syncing is complete.',
  'kitchenDisplayScreenSettings.virtual-kds.form.modal.kds-server-updated.got-it': 'Got It',
};
