export default {
  'transaction.title': 'Transactions',

  'transaction.filter.search': 'Filter',
  'transaction.filter.dateRange': 'Search by date range',
  'transaction.filter.paymentStatus': 'Payment Status',
  'transaction.filter.refundStatus': 'Refund Status',
  'transaction.filter.paymentType': 'Payment Type',

  'transaction.search.placeholder': 'Order #/Ticket #/Table #/Last 4 digit',
  'transaction.search.placeholder1': 'Order #',
  'transaction.status.placeholder': 'Select One',

  'transaction.column.time': 'Time created',
  'transaction.column.orderNumber': 'Order #',
  'transaction.column.cardLast4digit': 'Card last 4 digits',
  'transaction.column.tip': 'Tips',
  'transaction.column.fees': 'Fees',
  'transaction.column.total': 'Paid total',
  'transaction.column.status': 'Payment Status',
  'transaction.column.settleStatus': 'Settlement Status',
  'transaction.column.paymentStatus': 'Payment Status',
  'transaction.column.refundStatus': 'Refund Status',
  'transaction.column.totalRefund': 'Refund total',
  'transaction.column.paymentType': 'Payment Type',

  'transaction.refund.title': 'Choose Way Of Refund',
  'transaction.canotRefund.tips':
    'Sorry, we only support refund on transactions within 60 days. We apologize for the inconvenience. ',

  'transaction.action.fullRefund': 'Full Refund',
  'transaction.action.partiallyRefund': 'Refund by Amount',

  'transaction.refund.promptTitle': 'Refund Prompt',
  'transaction.refund.promptContent': 'Sure to refund {amount} for order {orderNo}',

  'transaction.refund.cannotFund': 'Cannot complete the refund',
  'transaction.refund.amount': 'Refund',
  'transaction.refund.total': 'Bill total',
  'transaction.refund.tips': 'Tips',
  'transaction.refund.totalRefunded': 'Refunded',
  'transaction.refund.includeTips': 'Include Tips',

  'transaction.receipt.subtotal': 'Subtotal',
  'transaction.receipt.fees': 'Fees',
  'transaction.receipt.tax': 'Tax',
  'transaction.receipt.discount': 'Discount',
  'transaction.receipt.tips': 'Tips',
  'transaction.receipt.total': 'Total',
  'transaction.receipt.cardNumber': 'US DEBIT',
  'transaction.receipt.transactionNumber': 'Transaction #',
  'transaction.receipt.normalPayment': 'Payment Type',
  'transaction.receipt.splitPayment': 'Split Payment',
  'transaction.receipt.paymentTotal': 'Payment Total',
  'transaction.receipt.customerSignature': 'Customer Signature',
};
