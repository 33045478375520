export default {
  'ordrReviews.contract.inactive.title': 'Customer Reviews',
  'ordrReviews.contract.inactive.desc1':
    'Improve service quality and earn more positive reviews on Google/Yelp platforms.',
  'ordrReviews.contract.inactive.desc2': `You have not purchased the <span style="color:#E00051">Reviews</span> service, please contact Chowbus customer success manager to order the service.`,
  'ordrReviews.contract.customerSuccessManager.email': `Email`,
  'ordrReviews.contract.customerSuccessManager.mobile': `Mobile`,
  'orderReviews.settings.title': 'Reviews Settings',
  'orderReviews.settings.invitations.title': 'Customer Review Invitations',
  'orderReviews.settings.invitations.tips':
    'Collect user feedback via SMS and QR codes, and encourage customers to review the restaurant on the platform.',
  'orderReviews.settings.basicSetting.title': 'Basic Settings',
  'orderReviews.settings.basicSetting.sms.inviteCustomersForFeedback.title': 'Request Feedback via SMS',
  'orderReviews.settings.basicSetting.sms.inviteCustomersForFeedback.option.triggerAfterPay.title':
    'After order payment, invite feedback when sending the electronic receipt',
  'orderReviews.settings.basicSetting.sms.inviteCustomersForFeedback.option.triggerAfterPay.tips':
    'Ideal for a pay-after-meal model, where the receipt is sent to customers after they have finished dining.',
  'orderReviews.settings.basicSetting.sms.inviteCustomersForFeedback.option.noFeedback.title':
    'Deactivate SMS channel for feedback collection',
  'orderReviews.settings.basicSetting.receiptPromptForFeedback.title': 'Receipt Prompt for Feedback',
  'orderReviews.settings.basicSetting.receiptPromptForFeedback.option.title': `To add a QR code for feedback on your receipt, <a href="/settings/printouts?key=receipt" target="_self">click here to set it up ></a>`,

  'orderReviews.settings.basicSetting.inviteCustomersForFeedback.title': 'Invite Customers for Feedback',
  'orderReviews.settings.basicSetting.inviteCustomersForFeedback.enable.title': 'Yes',
  'orderReviews.settings.basicSetting.inviteCustomersForFeedback.disable.title': 'No',

  'orderReviews.settings.platformSetting.title': 'Platform Settings',
  'orderReviews.settings.platformSetting.guideToPlatformReview.title': 'Guide to Platform Review',
  'orderReviews.settings.platformSetting.guideToPlatformReview.tips':
    'Select platform, guide high-rated customers to leave reviews on it, and help the restaurant acquire more positive reviews.',
  'orderReviews.settings.platformSetting.guideToPlatformReview.platform.google': 'Google',
  'orderReviews.settings.platformSetting.guideToPlatformReview.platform.yelp': 'Yelp',
  'orderReviews.settings.platformSetting.guideToPlatformReview.platform.google.placeholder':
    'Please enter the URL of your store on Google',
  'orderReviews.settings.platformSetting.guideToPlatformReview.platform.yelp.placeholder':
    'Please enter the URL of your store on Yelp',
  'orderReviews.settings.platformSetting.guideToPlatformReview.platform.preview': 'Preview',
  'orderReviews.settings.platformSetting.ratingStandard.title': 'Rating Standard',
  'orderReviews.settings.platformSetting.ratingStandard.tips':
    'Set a rating standard and guide highly rated customers to leave their reviews.',
  'orderReviews.settings.platformSetting.ratingStandard.starsSelector.tail':
    'or above, guide to leave a review on the platform',
  'orderReviews.settings.platformSetting.guideToPlatformReview.platform.google.invalidUrl': 'invalid url address',
  'orderReviews.settings.platformSetting.guideToPlatformReview.platform.yelp.invalidUrl': 'invalid url address',

  'orderReviews.details.title': 'Reviews Details',
  'orderReviews.details.query.seachByDateRange': 'Search by date range',
  'orderReviews.details.query.seachByDateRange.default': 'Default',
  'orderReviews.details.query.rate': 'Rating',
  'orderReviews.details.query.rate.placeHolder': 'Multiple',
  'orderReviews.details.query.guideToPlatform': 'Guide to platform',
  'orderReviews.details.query.keywordSearch': 'Filter',
  'orderReviews.details.query.keywordSearch.placeHolder': 'Advanced Search',
  'orderReviews.details.query.guideToPlatform.placeHolder': 'Multiple',

  'orderReviews.report.title': 'Reviews Report',
  'orderReviews.report.totalRating': 'Total ratings',
  'orderReviews.report.averageRating': 'Average rating',

  'orderReviews.details.action.clear': 'Clear',

  'orderReviews.column.created_at': 'Time of review',
  'orderReviews.column.rating': 'Rating',
  'orderReviews.column.guide_To_latform': 'Guide to platform',
  'orderReviews.column.review_content': 'Review content',
  'orderReviews.column.customer_name': 'Customer name',
  'orderReviews.column.phone_number': 'Phone number',
  'orderReviews.column.order_number': 'Order number',

  'orderReviews.review.imageShower.title': 'The image on review',

  'orderReviews.rating.percent': 'of the {total}',
  'orderReviews.rating.legend.one_star': '1 star',
  'orderReviews.rating.legend.two_star': '2 stars',
  'orderReviews.rating.legend.three_star': '3 stars',
  'orderReviews.rating.legend.four_star': '4 stars',
  'orderReviews.rating.legend.five_star': '5 stars',

  'orderReviews.settings.updateSuccess': 'Successfully updated settings',
  'orderReviews.detail.advanceSearchPlacehold': 'Search by keywords',

  // for C client
  'orderReviews.to_c.enter.your.phonenumber': 'Enter Phone Number',
  'orderReviews.to_c.enter.your.phonenumber.desc': `Please enter your order phone number, and we'd love your invaluable feedback to help us improve.`,
  'orderReviews.to_c.enter.your.phonenumber.confirm.button': 'Confirm',
  'orderReviews.to_c.enter.anonymous.button': 'Anonymous review',
  'orderReviews.to_c.pleaseRate.title': 'Please Rate',
  'orderReviews.to_c.pleaseRate.desc': 'Thank you for visiting {restaurantName}, please rate our service.',
  'orderReviews.to_c.pleaseRate.continue.tips': 'Please keep giving feedback and leaving valuable comments.',
  'orderReviews.to_c.pleaseRate.continue.button': 'Submit',
  'orderReviews.to_c.review.title': 'Order Review',
  'orderReviews.to_c.review.desc': 'Please leave your valuable comments and feedback.',
  'orderReviews.to_c.comments.placeholder': 'Write your feelings ',
  'orderReviews.to_c.addPicture.placeholder': 'Add photos',
  'orderReviews.to_c.comments.submit.button': 'Submit',
  'orderReviews.to_c.thanks.title': `Thanks for 
  your feedback!`,
  'orderReviews.to_c.thanks.desc': `We will continue improving and look forward to your next visit.`,
  // 'orderReviews.to_c.thanks.more.desc': `We invite you to continue reviewing us on the platform and share both our strengths and weaknesses.`,
  'orderReviews.to_c.thanks.more.desc': `Copy your review and paste it directly on the platform’s posting page.`,
  'orderReviews.to_c.thanks.more.title': `Let more friends get to know us 
  and inspire us to continuously improve`,
  'orderReviews.to_c.thanks.more.google.review.button': 'Go to Google',
  'orderReviews.to_c.thanks.more.yelp.review.button': 'Go to Yelp',

  'orderReviews.action.uploadPhoto': 'Upload Photo',
  'orderReviews.image.description': 'The format is {fileTypes}, should be within {fileMaxSize}M',
  'orderReviews.image.tooLargeSize': 'File size needs to be {fileMaxSize}MB or smaller',
  'orderReviews.image.tooSmallSize': 'Image must be have width and height of at least 320 x 320',
  'orderReviews.resolutionAdjusted': 'Resolution has been adjusted',
  'orderReviews.viewOriginalPhoto': 'View original photo',
  'orderReviews.handleImageFailed': 'Handle image failed, please re-operate',
  'orderReviews.wrongFileType': 'File needs to be {fileTypes}',

  'orderReviews.page.title': 'Chowbus POS Order Review',
  'orderReviews.to_c.enter.your.phonenumber.consent': `Agree to the <a href="https://www.chowbus.com/terms-of-use" target="_blank">Terms of Use</a> & <a href="https://www.chowbus.com/privacy-policy" target="_blank">Privacy Policy</a> and receive promotional messages from Chowbus.`,

  'orderReviews.submit.alert.backButton': 'Back',
  'orderReviews.submit.alert.retryButton': 'Retry',
  'orderReviews.submit.alert.message': 'Network error. Please try again later.',

  'orderReviews.to_c.copytoClipboardButton': 'Copy Review',
  'orderReviews.to_c.copied.message': 'Copied',
};
